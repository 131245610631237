import moment from "moment"

export default class PatientModel {
    constructor(obj: any = null) {
        if (obj)
            Object.assign(this, obj)
    }

    id = 0
    cedula = ''
    firstName = ''
    lastName = ''
    dob = moment().format()
    gender = ''
    phone = ''
    email = ''
    address = ''
    doctor = 0
    insurance = ''
    affiliateCode = ''
    age = ''
    fullName = ''

    names = `${this.firstName} ${this.lastName}`
}