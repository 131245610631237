import React, { useCallback, useEffect, useState } from 'react'
import { Typography, PageHeader, Modal, Divider, List, notification, Row, Col, Button, Skeleton, Input } from 'antd'
import API from '../../utils/API'
import { useSelector } from 'react-redux'
import MyHomologations from './MyHomologations'
import UserAssistant from '../../models/UserAssistant'
import _ from 'lodash'
import { Filter } from '@progress/kendo-react-data-tools'

const { confirm } = Modal

export default () => {

    const [drug, setDrug] = useState({} as any)
    const [carolData, setData] = useState(new Array())
    const [loading, setLoading] = useState(false)
    const [loadingList, setLoadingList] = useState(false)
    const [filter, setFilter] = useState('')
    const [showMyHomologations, setShowMyHomologations] = useState(false)

    const { user } = useSelector((state: any) => state)

    useEffect(() => {
        getNext()
    }, [user?.username])

    const delayedQuery = useCallback(_.debounce((str: string) => searchCarol(str), 500), [carolData.length]);

    const filterCarol = (str: string) => {
        setFilter(str)
        delayedQuery(str)
    }

    const fplDrug = `${drug?.nombreProducto} (${drug?.concentracion}) ${drug?.formaDosis} [${drug?.packageContentQuantity} / ${drug?.packageContentSize} - ${drug?.nombreEmpaque}] ${drug?.formaAdministracion}  [${drug?.substanciaControlada}]`

    const toggleMyHomologations = () => setShowMyHomologations(!showMyHomologations)

    const getNext = () => {
        setLoading(true)
        API.get(`carol/getnext?user=${user?.username}`)
            .then((resp: any) => {
                setDrug({ ...resp.data })

                try {
                    filterCarol(resp.data.nombreProducto.split(' ')[0] ?? '')
                } catch (error) {
                    console.error("*** An error happened splitting drug name ***")
                }

            })
            .catch(() => notification.error({ message: "Error al obtener medicamento" }))
            .finally(() => {
                setLoading(false)
            })
    }

    const searchCarol = (str: string) => {
        if(!str) return;
        setLoadingList(true)
        API.get(`carol/getcarollist?term=${str.trim()}`)
            .then((resp: any) => setData([...resp.data ]))
            .catch(() => notification.error({ message: "Error al obtener medicamento" }))
            .finally(() => {
                setLoadingList(false)
            })
    }

    const setCode = (carol_drug: any) => {
        const body: any = {
            carol_drug,
            user: user?.username,
            ndc: drug?.ndc
        }

        confirm({
            onOk: () => API.post('carol/setcarolcode', body)
                .then((resp: any) => {
                    if (resp.status == 200) {
                        notification.success({ message: 'Homologado correctamente.' })
                        getNext()
                    }
                    else
                        notification.error({ message: 'Ocurrió un error al homologar.' })
                }),
            okText: 'Homologar',
            title: 'Homologación',
            content: <>
                <Typography.Text strong>[{carol_drug.name}]</Typography.Text>
                <Divider>*</Divider>
                <Typography.Text strong>
                    {fplDrug}
                </Typography.Text>
            </>
        })
    }

    return <>
        <PageHeader subTitle={`Homologación GC`}
            className="site-page-header"
            title={`Homologación GC`} />

        <Divider />

        <Row gutter={8}>
            <Col>
                <Button size='large' onClick={toggleMyHomologations}>Mis homologaciones</Button>
            </Col>
            <Col>
                <Button loading={loading} type='primary' size='large' onClick={getNext}>Próximo medicamento</Button>
            </Col>
        </Row>

        {loading ? <>'Buscando...'</> : <Typography.Text style={{ fontSize: '30px', textAlign: 'center' }} strong>{fplDrug}</Typography.Text>}

        <Divider />
        <Input
            style={{ width: '30%' }}
            placeholder='Buscar en archivo Carol...'
            value={filter}
            onChange={(e: any) => filterCarol(e.target.value)} />

        <Skeleton active loading={loading || loadingList}>
            <List
                style={{ overflowY: 'scroll' }}
                dataSource={carolData || []}
                renderItem={(drug: any, idx: number) => <List.Item onClick={() => setCode(drug)}>
                    <List.Item.Meta title={drug?.name} description={drug?.barcode} />
                </List.Item>}
            />
        </Skeleton>

        <MyHomologations close={toggleMyHomologations} visible={showMyHomologations} user={user?.username} />
    </>
}