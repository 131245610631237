import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Row, Form, Typography, Col, AutoComplete, notification, Select, Tooltip, PageHeader, Modal, Checkbox, Button, Input, Card, Dropdown, Menu, Tag, Divider } from 'antd'
import { PlusOutlined, DeleteOutlined, ManOutlined, WomanOutlined, SearchOutlined, SaveFilled, ExclamationCircleOutlined } from '@ant-design/icons'
import API from '../../utils/API'
import { AxiosError, AxiosResponse } from 'axios'
import PatientModel from '../../models/Patient'
import moment from 'moment'
import logoalt from '../../assets/images/logoalt.png'
import banner_square from '../../assets/images/banner_square.jpg'
import PdfViewer from '../common/pdfViewer'
import _ from 'lodash'

const { Option } = Select
const { confirm } = Modal

interface Drug {
    idx: number,
    ndc: string,
    drug: string,
    dosage: string,
    dosageList: any[],
    strength: string,
    strengthList: string[],
    quantity: number,
    days: number,
    showComment: boolean,
    comment: string,
    administrationComment: string,
    type: string,
    isGeneric: boolean,
    drugBankId: string
}

interface Pharmacy {
    id: number,
    name: string,
    chain: string,
    address_one: string,
    address_two: string,
    phone: string
}

interface Option {
    value: string,
    id: string,
    data?: any
}

interface DrugSearch {
    drug: string,
    generic: boolean,
    index: number
}

export default () => {

    const [drugs, setDrugs] = useState<Drug[]>([])
    const [patient, setPatient] = useState<PatientModel>(new PatientModel())
    const [pharmacy, setPharmacy] = useState<Pharmacy>({} as Pharmacy)
    const [options, setOptions] = useState<Option[]>([])
    const [patients, setPatients] = useState<Option[]>([])
    const [pharmacies, setPharmacies] = useState<Option[]>([])
    const [showDrawer, setShowDrawer] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [isFuturoAffiliate, setIsFuturoAffiliate] = useState(false)
    const [isFuturoSearchLoading, setIsFuturoSearchLoading] = useState(false)
    const [savedData, setSavedData] = useState<any[]>([])
    const [centers, setCenters] = useState<any[]>([])
    const [center, setCenter] = useState<any>({})
    const [bannerVisible, setBannerVisible] = useState(false)
    const [drugSearch, setDrugSearch] = useState<DrugSearch>({
        drug: '',
        generic: false,
        index: 0
    })

    const toggleBannerVisible = () => setBannerVisible(!bannerVisible)

    const delayedQuery = useCallback(_.debounce((q: DrugSearch) => getDrugs(q.drug, q.generic, q.index), 500), [drugs.length]);

    const onDrugSearch = (value: string, generic: boolean, idx: number) => {
        setDrugSearch({ ...{ drug: value, generic: generic, index: idx } })
        delayedQuery({ ...{ drug: value, generic: generic, index: idx } })
    }

    const { user } = useSelector((state: any) => state)

    useEffect(() => {
        if (!drugs.length)
            addRow()
    }, [])

    useEffect(() => {
        getCenters()
    }, [center == {}])

    const [drugForm] = Form.useForm()

    const toggleDrawerVisibility = () => setShowDrawer(!showDrawer)

    const layout = {
        labelCol: { span: 2 },
        wrapperCol: { span: 22 },
    };

    const tailLayout = {
        wrapperCol: { offset: 0, span: 24 }
    };

    const headerLayout = {
        wrapperCol: { offset: 4, span: 16 }
    };

    const defaultDrug = {
        idx: 0,
        ndc: '',
        drug: '',
        dosage: '',
        dosageList: [],
        strength: '',
        strengthList: [],
        quantity: 0,
        days: 0,
        showComment: false,
        comment: '',
        administrationComment: '',
        type: 'Consulta',
        isGeneric: false,
        drugBankId: ''
    }


    const addRow = () => {
        const maxIdx = drugs.length > 0 ? Math.max.apply(Math, drugs.map(function (o) { return o.idx; })) : -1
        drugs.push({ ...defaultDrug, ...{ idx: maxIdx + 1 } })
        setDrugs([...drugs])
    }

    const getInteractions = async () => {
        const { data } = await API.post('drug/GetInteractions', drugs.map((drug: Drug) => drug.ndc))
        return data;
    }

    const getDrugs = (value: string, isGeneric: boolean, index: number) => {
        if (value.length < 3) return;

        console.log('inside', drugs)

        drugs[index].drug = value
        API.post('drug/GetDrugByName', { NombreProducto: value, isGeneric: isGeneric })
            .then((response: AxiosResponse) => {
                setDrugs([...drugs])
                setOptions([...response.data])
            })
            .catch((err: AxiosError) => console.log("An error ocurred"))


    }

    const getPatients = async (value: string) => {
        const { data } = await API.post('patient/search', { value, doctor: user?.data?.id })
        setPatients([...data])
    }

    const getPharmacies = async (value: string) => {
        const { data } = await API.post('pharmacy/search', { value })
        setPharmacies([...data])
    }

    const getCenters = async () => {
        const { data } = await API.get(`user/getDoctorCenters?doctorId=${user?.data?.id}`)
        setCenters([...data])
        setCenter(data[0])
    }

    const onCenterSelected = (e: any) => setCenter({ ...center, ...centers.filter((c: any) => c.id === e.item.props.id)[0] })

    const onPatientSelected = async (value: string, option: any) => {
        const { data } = await API.post(`patient/get`, { id: option.id })
        setPatient(data)
    }

    const searchFuturoAffiliate = (value: string) => {
        setIsFuturoSearchLoading(true)
        API.post(`patient/searchbyclient`, { clientId: 1, pharmacyId: 190, affiliateCode: value })
            .then((response: AxiosResponse) => {
                let _patient = new PatientModel(response.data[0].patient)
                setPatient(_patient)
            })
            .catch(() => notification.error({
                message: 'Paciente',
                description: 'Ocurrio un error al buscar el afiliado en ARS FUTURO'
            }))
            .finally(() => setIsFuturoSearchLoading(false))
    }

    const onDrugSelected = async (value: string, option: any, index: number, isGeneric: boolean) => {
        var idx = drugs.findIndex((drug: Drug) => drug.idx === index)
        const { data } = await API.post('drug/GetDosageForm', { NombreProducto: value, isGeneric })

        drugs[idx].drug = value
        drugs[idx].ndc = option.id
        drugs[idx].dosageList = data
        drugs[idx].dosage = data.length == 1 ? data[0] : ''
        drugs[idx].strength = ''
        drugs[idx].strengthList = []
        drugs[idx].quantity = 0
        drugs[idx].days = 0
        setDrugs([...drugs])
        if (drugs[idx].dosage)
            onDosageSelected(drugs[idx].dosage, idx, drugs[idx].isGeneric)
    }

    const onDosageSelected = async (value: string, index: number, isGeneric: boolean) => {
        var idx = drugs.findIndex((drug: Drug) => drug.idx === index)

        const { data } = await API.post('drug/GetStrength', { NombreProducto: drugs[idx].drug, FormaDosis: value, isGeneric })
        drugs[idx].dosage = value
        drugs[idx].strengthList = data
        drugs[idx].strength = data.length == 1 ? data[0] : ''
        drugs[idx].quantity = 0
        drugs[idx].days = 0
        setDrugs([...drugs])
    }

    const onStrengthSelected = (value: string, index: number) => {
        var idx = drugs.findIndex((drug: Drug) => drug.idx === index)
        drugs[idx].strength = value
        drugs[idx].quantity = 0
        drugs[idx].days = 0
        setDrugs([...drugs])
    }

    const onPharmacySelected = (value: string, option: any) => setPharmacy(option.data)

    const onDaysChange = (value: any, index: number) => {
        var idx = drugs.findIndex((drug: Drug) => drug.idx === index)
        drugs[idx].days = Number.parseInt(value.target.value) > 120 ? '' : value.target.value
        setDrugs([...drugs])
    }

    const onQuantityChange = (value: any, index: number) => {
        var idx = drugs.findIndex((drug: Drug) => drug.idx === index)
        drugs[idx].quantity = Number.parseInt(value.target.value) > 120 ? '' : value.target.value
        setDrugs([...drugs])
    }

    const onCommentChange = (value: any, index: number) => {
        var idx = drugs.findIndex((drug: Drug) => drug.idx === index)
        drugs[idx].comment = value.target.value
        setDrugs([...drugs])
    }

    const onAdministrationCommentChange = (value: any, index: number) => {
        var idx = drugs.findIndex((drug: Drug) => drug.idx === index)
        drugs[idx].administrationComment = value.target.value
        setDrugs([...drugs])
    }

    const onCheckedChange = (value: any, index: number) => {
        var idx = drugs.findIndex((drug: Drug) => drug.idx === index)
        drugs[idx].isGeneric = value.target.checked
        setDrugs([...drugs])
    }

    const onFuturoCheckedChange = (e: any) => setIsFuturoAffiliate(e.target.checked)

    const onTypeChange = (value: any, index: number) => {
        var idx = drugs.findIndex((drug: Drug) => drug.idx === index)
        drugs[idx].type = value
        setDrugs([...drugs])
    }

    const removeDrug = (index: number) => {
        let idx = drugs.findIndex((drug: Drug) => drug.idx === index)
        drugs.splice(idx, 1)
        setDrugs([...drugs])
    }

    const getAge = () => {
        var diff_ms = Date.now() - new Date(patient.dob).getTime();
        var age_dt = new Date(diff_ms);
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    const isLastDrug = (index: number) => Math.max.apply(Math, drugs.map((o: any) => o.idx)) === index

    const getSeverity = (str: string) => {
        let val = { text: 'Menor', color: 'green' }

        switch (str) {
            case 'minor': val = { text: 'Menor', color: 'green' }
                break;
            case 'moderate': val = { text: 'Moderado', color: 'gold' }
                break;
            case 'major': val = { text: 'Mayor', color: 'red' }
                break
        }

        return val
    }

    const save = async () => {
        const interactions = await getInteractions()

        if (interactions.total_results) {
            confirm({
                content: <>
                    {interactions.interactions.map((interaction: any, idx: number) => <>
                        <Divider>{`Interacción #${idx + 1}`}</Divider>
                        <p>
                            Interacción entre <b>{interaction?.ingredient?.name}</b> y <b>{interaction?.affected_ingredient?.name}</b>
                        </p>
                        <p>
                            <Tag color={getSeverity(interaction?.severity).color}>
                                {`Riesgo: ${getSeverity(interaction?.severity).text}`}
                            </Tag>
                        </p>
                        <p>
                            <Typography.Text strong>{interaction?.description}</Typography.Text>
                        </p>

                    </>)}
                </>,
                okText: 'Generar receta',
                onOk: () => {

                    generate()
                    saveInteractionsAudit(interactions.interactions, true)
                },
                onCancel: () => saveInteractionsAudit(interactions.interactions, false),
                cancelText: 'Cancelar'
            })
        }
        else {
            confirm({
                title: 'Prescripción',
                content: '¿Desea guardar la prescripción?',
                icon: <ExclamationCircleOutlined />,
                onOk: async () => generate()
            })
        }
    }

    const saveInteractionsAudit = (interactions: any, approved: boolean) => {
        const obj = new Array()
        interactions.forEach((i: any) => obj.push({
            severity: i.severity,
            ingredient: i.ingredient.name,
            affected_ingredient: i.affected_ingredient.name,
            action: i.action,
            description: i.description,
            approved,
            prescriber_id: user?.data?.id,
            patient_id: patient?.id
        }))

        API.post('drug/saveInteractions', obj)
    }

    const generate = async () => {

        setSaveLoading(true)
        toggleBannerVisible()

        const APIObject = {
            patient: patient,
            pharmacy: pharmacy,
            doctor: user?.data?.id,
            drugs: drugs,
            center: center.id
        }

        const { data } = await API.post('prescription/save', APIObject)

        notification.success({
            placement: 'topLeft',
            message: 'Prescripción',
            description: 'Se guardó correctamente la prescripción'
        })

        setSavedData(data)
        setSaveLoading(false)
        setPatient(new PatientModel)
        setPharmacy({} as Pharmacy)
        setDrugs([...[defaultDrug]])
    }

    const getGengerIcon = () => patient.gender === 'M' ? <ManOutlined style={{ color: 'skyblue' }} /> : <WomanOutlined style={{ color: 'pink' }} />

    const getFormattedCedula = () => {
        if (!patient.id || !patient.cedula) return ''

        const c = patient.cedula
        return `${c.substring(0, 3)}-${c.substring(3, 10)}-${c.substring(10, 11)}`
    }

    const getFormattedPhone = () => {
        if (!patient.id || !patient.phone || patient.phone.length < 10) return '(Vacío)'
        const p = patient.phone
        return `${p.substring(0, 3)}-${p.substring(3, 6)}-${p.substring(6, 10)}`
    }

    const isDrugOk = () => drugs.every((drug: Drug) =>
        drug.drug
        && drug.dosage
        && drug.strength
        && drug.quantity
        && drug.administrationComment
        && drug.days)

    const canSave = () => {
        const drugOk = isDrugOk()

        const patientOk = !!patient.id
        const pharmacyOk = !!pharmacy.id

        return drugOk && patientOk && pharmacyOk && emergencyOk()
    }

    const emergencyOk = () => {
        let emergencyTypeValidation = true
        const emergencyCount = drugs.filter((drug: Drug) => drug.type == 'Emergencia').length
        if (emergencyCount)
            emergencyTypeValidation = emergencyCount == drugs.length
        return emergencyTypeValidation
    }

    const canAddNextDrug = (index: number) => {
        let idx = drugs.findIndex((drug: Drug) => drug.idx === index)
        let drug = drugs[idx]
        return drug.drug && drug.dosage && drug.strength && drug.quantity && drug.days && drug.administrationComment
    }

    const canDeleteRow = () => drugs.length > 1

    const isFuturo = user?.data?.isFuturo

    return <>
        <PageHeader
            className="site-page-header"
            extra={<Dropdown overlay={<Menu>
                {centers.map((center: any) => <Menu.Item onClick={onCenterSelected} key={center.id} id={center.id}>{center.name}</Menu.Item>)}
            </Menu>}>
                <Button type={'link'} style={{ top: '15px' }}>{center.name}</Button>
            </Dropdown>}
            title={<img src={logoalt} style={{ height: '50px' }} />}>

        </PageHeader>
        <Form
            form={drugForm}
            {...layout}
            name={'login'}
            style={{ margin: 10 }}>

            <Form.Item
                {...headerLayout}
                name={'patient'}>
                <Row gutter={12}>
                    <Col span={isFuturo ? 9 : 12} offset={isFuturo ? 3 : 0}><Typography.Text strong>Paciente</Typography.Text></Col>
                    <Col span={12}><Typography.Text strong>Farmacia</Typography.Text></Col>
                </Row>
                <Row gutter={12} justify={'space-around'}>
                    {isFuturo && <Col span={3}>
                        <Checkbox value={isFuturoAffiliate} onChange={onFuturoCheckedChange}>Futuro</Checkbox>
                    </Col>}
                    <Col span={isFuturo ? 9 : 12}>
                        {isFuturoAffiliate ?
                            <Input.Search
                                placeholder={'Número de afiliado'}
                                loading={isFuturoSearchLoading}
                                onSearch={searchFuturoAffiliate}
                            /> : <AutoComplete
                                suffixIcon={<SearchOutlined />}
                                autoClearSearchValue={true}
                                showArrow
                                placeholder={'Paciente'}
                                onSelect={onPatientSelected}
                                onSearch={getPatients}
                                options={patients} />
                        }
                    </Col>
                    <Col span={12}>
                        <AutoComplete
                            suffixIcon={<SearchOutlined />}
                            showArrow
                            placeholder={'Escriba el nombre de la farmacia'}
                            onSelect={onPharmacySelected}
                            onSearch={getPharmacies}
                            options={pharmacies} />
                    </Col>
                </Row>

            </Form.Item>
            <Form.Item
                {...tailLayout}
                name={'data'}>
                <Row gutter={12} justify={'space-around'}>
                    <Col span={12}>
                        {patient.id ? <Card style={{ textAlign: 'center', boxShadow: '1px 5px 10px gainsboro' }}>
                            <Typography.Paragraph>
                                <Typography.Title level={3}>
                                    {getGengerIcon()}{`${patient.firstName} ${patient.lastName} (${getAge()})`}
                                </Typography.Title>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Row>
                                    <Col span={6}>
                                        <Typography.Text strong>Identificación</Typography.Text>
                                    </Col>
                                    <Col span={6}>
                                        <Typography.Text strong>Fecha de nacimiento</Typography.Text>
                                    </Col>
                                    <Col span={6}>
                                        <Typography.Text strong>Aseguradora</Typography.Text>
                                    </Col>
                                    <Col span={6}>
                                        <Typography.Text strong># Afiliado</Typography.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}> <Typography.Text>{getFormattedCedula()}</Typography.Text> </Col>
                                    <Col span={6}> <Typography.Text>{moment(patient.dob).format('DD/MM/YYYY')}</Typography.Text></Col>
                                    <Col span={6}> <Typography.Text>{patient.insurance || '(Vacío)'} </Typography.Text></Col>
                                    <Col span={6}> <Typography.Text>{patient.affiliateCode || '(Vacío)'}</Typography.Text></Col>
                                </Row>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Row>
                                    <Col span={6}>
                                        <Typography.Text strong>Teléfono</Typography.Text>
                                    </Col>
                                    <Col span={6}>
                                        <Typography.Text strong>e-Mail</Typography.Text>
                                    </Col>
                                    <Col span={12}>
                                        <Typography.Text strong>Dirección #1</Typography.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={6}><Typography.Text>{getFormattedPhone()}</Typography.Text></Col>
                                    <Col span={6}><Typography.Text>{patient.email || '(Vacío)'}</Typography.Text></Col>
                                    <Col span={12}><Typography.Text>{patient.address || '(Vacío)'}</Typography.Text></Col>
                                </Row>
                            </Typography.Paragraph>
                        </Card> : <></>}
                    </Col>
                    <Col span={12}>
                        {pharmacy.id ? <Card style={{ textAlign: 'center', boxShadow: '1px 5px 10px gainsboro' }}>
                            <Typography.Paragraph>
                                <Typography.Title level={3}>
                                    {pharmacy.chain}
                                </Typography.Title>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Row>
                                    <Col span={18}>
                                        <Typography.Text strong>Nombre</Typography.Text>
                                    </Col>
                                    <Col span={6}>
                                        <Typography.Text strong>Teléfono</Typography.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={18}><Typography.Text>{pharmacy.name}</Typography.Text></Col>
                                    <Col span={6}><Typography.Text>{pharmacy.phone}</Typography.Text></Col>
                                </Row>
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Row>
                                    <Col span={12}>
                                        <Typography.Text strong>Dirección #1</Typography.Text>
                                    </Col>
                                    <Col span={12}>
                                        <Typography.Text strong>Dirección #2</Typography.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}><Typography.Text>{pharmacy.address_one}</Typography.Text></Col>
                                    <Col span={12}><Typography.Text>{pharmacy.address_two || '(Vacío)'}</Typography.Text></Col>
                                </Row>
                            </Typography.Paragraph>
                        </Card> : <></>}
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item
                {...tailLayout}>
                <Row gutter={8} style={{ padding: '10px', width: '100%' }}>
                    <Col span={2} style={{ textAlign: 'center' }}><Typography.Text strong>Genérico</Typography.Text></Col>
                    <Col span={9}><Typography.Text strong>Medicamento</Typography.Text></Col>
                    <Col span={4}><Typography.Text strong>Forma</Typography.Text></Col>
                    <Col span={4}><Typography.Text strong>Concentración</Typography.Text></Col>
                    <Col span={2}><Typography.Text strong>Cantidad</Typography.Text></Col>
                    <Col span={1} style={{ textAlign: 'center' }}><Typography.Text strong>Días</Typography.Text></Col>
                    <Col span={1} />
                </Row>

                {drugs.map((drug: Drug) => <><Row key={drug.idx} style={{
                    marginBottom: '10px',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    padding: '10px',
                    boxShadow: '2px 2px 2px gainsboro'
                }}>
                    <Row gutter={8} style={{ width: '100%' }}>

                        <Col span={1}>
                            {canDeleteRow() && isLastDrug(drug.idx) ? <Tooltip overlay={'Remover'}>
                                <Button type={'link'} onClick={() => removeDrug(drug.idx)} icon={<DeleteOutlined />} />
                            </Tooltip> : <></>}
                        </Col>
                        <Col span={1}>
                            <Tooltip overlay={'Medicamento genérico'}>
                                <Checkbox onChange={(e: any) => onCheckedChange(e, drug.idx)} />
                            </Tooltip>
                        </Col>
                        <Col span={9}>
                            <AutoComplete
                                // value={drug.drug} 
                                placeholder={drug.isGeneric ? 'Principio activo' : 'Nombre del medicamento'}
                                onSelect={(value: any, option: any) => onDrugSelected(value, option, drug.idx, drug.isGeneric)}
                                onSearch={(value: string) =>
                                    onDrugSearch(value, drug.isGeneric, drug.idx)
                                    // getDrugs(value, drug.isGeneric, drug.idx)
                                }
                                options={options} />
                        </Col>
                        <Col span={4}>
                            <Tooltip overlay={drug.dosage || 'Dosis'}>
                                <Select
                                    placeholder={'Dosis'}
                                    value={drug.dosage}
                                    onChange={(value: any) => onDosageSelected(value, drug.idx, drug.isGeneric)}>
                                    {drug.dosageList.map((dosage: string) => <Option value={dosage} key={dosage}>{dosage}</Option>)}
                                </Select>
                            </Tooltip>
                        </Col>
                        <Col span={4}>
                            <Tooltip overlay={drug.strength}>
                                <Select
                                    placeholder={'Concentración'}
                                    value={drug.strength}
                                    onChange={(value: any) => onStrengthSelected(value, drug.idx)}>
                                    {drug.strengthList.map((strength: string) => <Option value={strength} key={strength}>{strength}</Option>)}
                                </Select>
                            </Tooltip>
                        </Col>
                        <Col span={2}>
                            <Input value={drug.quantity} onChange={(value: any) => onQuantityChange(value, drug.idx)} />
                        </Col>
                        <Col span={2}>
                            <Input value={drug.days} onChange={(value: any) => onDaysChange(value, drug.idx)} />
                        </Col>
                        {(isLastDrug(drug.idx)
                            && canAddNextDrug(drug.idx)
                            && drugs.length != 6) ? <Col span={1}>
                            <Button onClick={addRow} icon={<PlusOutlined />} />
                        </Col> : <></>
                        }
                    </Row>
                    <Row gutter={8} style={{ width: '100%', paddingTop: '10px' }}>
                        <Col offset={2} span={3}>
                            <Typography.Text strong>Tipo de receta</Typography.Text>
                        </Col>
                        <Col span={8}>
                            <Typography.Text strong>Forma de administración</Typography.Text>
                        </Col>
                        <Col span={8}>
                            <Typography.Text strong>Comentario (opcional)</Typography.Text>
                        </Col>
                    </Row>
                    <Row gutter={8} style={{ width: '100%', paddingTop: '10px' }}>
                        <Col offset={2} span={3}>
                            <Tooltip overlay={'Tipo de receta'}>
                                <Select onSelect={(value: string) => onTypeChange(value, drug.idx)} defaultValue={'Consulta'}>
                                    <Option key={0} value={'Consulta'}>Consulta</Option>
                                    <Option key={1} value={'Uso continuo'}>Uso continuo</Option>
                                    <Option key={2} value={'Emergencia'}>Emergencia</Option>
                                </Select>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Input.TextArea
                                value={drug.administrationComment}
                                onChange={(value: any) => onAdministrationCommentChange(value, drug.idx)} />
                        </Col>
                        <Col span={8}>
                            <Input.TextArea
                                value={drug.comment}
                                onChange={(value: any) => onCommentChange(value, drug.idx)} />
                        </Col>
                    </Row>
                </Row>
                </>)}
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Row>
                    {!patient.id && <Tag color={'red'}>Paciente requerido</Tag>}
                    <br />
                    {!pharmacy.id && <Tag color={'red'}>Farmacia requerida</Tag>}
                    <br />
                    {!isDrugOk() && <Tag color={'red'}>Medicamento requerido</Tag>}
                </Row>
            </Form.Item>


            <Form.Item
                style={{ textAlign: 'left' }}
                {...tailLayout}>
                <Button disabled={!canSave()} loading={saveLoading} icon={<SaveFilled />} onClick={save}>Guardar prescripción</Button>
                {!emergencyOk() && <Typography.Text strong style={{ color: 'red', marginLeft: '5px' }}>Si la receta contiene por lo menos un medicamento de tipo emergencia, los demás tambien deben serlo.</Typography.Text>}
            </Form.Item>
        </Form>

        {showDrawer && <PdfViewer
            onClose={toggleDrawerVisibility}
            visible={showDrawer}
            data={savedData}
            sendOnLoad={true} />}

        <Modal
            visible={bannerVisible}
            onCancel={toggleBannerVisible} destroyOnClose={true}
            closable={false}
            width={1000}
            okButtonProps={{
                loading: saveLoading,
            }}
            okText={saveLoading ? 'Generando receta' : 'Ver receta'}
            onOk={() => {
                toggleBannerVisible()
                toggleDrawerVisibility()
            }}>
            <img
                style={{ maxHeight: '100%', maxWidth: '100%', margin: 'auto', display: 'block' }}
                src={banner_square} />
        </Modal>
    </>
}