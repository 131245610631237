import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Button, notification } from 'antd'
import API from '../../utils/API'
import { AxiosError, AxiosResponse } from 'axios'
import CenterModel from '../../models/Center'

export default (props: any) => {

    const [centerForm] = Form.useForm()
    const [center, setCenter] = useState<CenterModel>(new CenterModel)

    useEffect(() => {
        if (props.center?.id)
            setCenter(props.center)
        else
            setCenter(new CenterModel())
    }, [props.visible])

    useEffect(() => {
        centerForm.setFieldsValue(center)
    }, [center])

    const send = () => API.post(props.center?.id ? 'center/update' : 'center/add', center)
        .then(() => {
            notification.success({
                message: 'AVISO',
                description: `Centro guardado satisfactoriamente`
            })
            props.close()
        })
        .catch((error: AxiosError) => console.log(error.response?.data));

    const onChange = (e: any) => setCenter({ ...center, ...{ [e.target.name]: e.target.value } })

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    const tailLayout = {
        wrapperCol: { offset: 6, span: 16 },
    };

    return <Modal
        visible={props.visible}
        onCancel={props.close}
        footer={[]}
        title={props.center?.id ? `${props.center?.name}` : 'Center'}>
        <>
            <Form
                {...layout}
                form={centerForm}
                name={'user'}
                onFinish={send}>
                <Form.Item name="name" label="Nombre" rules={[{ required: true }]}>
                    <Input name={"name"} onChange={onChange} />
                </Form.Item>
                <Form.Item name="address" label="Dirección" rules={[{ required: true }]}>
                    <Input name={"address"} onChange={onChange} />
                </Form.Item>
                <Form.Item name="phone" label="Teléfono" rules={[{ required: true }]}>
                    <Input name={"phone"} onChange={onChange} />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button
                        type={'primary'}
                        htmlType={'submit'}>{props.center?.id ? 'Actualizar' : 'Guardar'}</Button>
                </Form.Item>
            </Form>
        </>
    </Modal>
}