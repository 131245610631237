import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Typography, PageHeader, Table, Statistic, Row, Col, Card, Button, Popover, Menu, Modal, Input, AutoComplete, Tooltip, Dropdown, notification, Timeline } from 'antd'
import { FileDoneOutlined, FileExclamationOutlined, FolderOpenOutlined, ExclamationOutlined, EditOutlined, SearchOutlined, StopOutlined } from '@ant-design/icons'
import API from '../../utils/API'
import { AxiosResponse, AxiosError } from 'axios'
import moment from 'moment'
import { PDFViewer } from '@react-pdf/renderer'
import PrescriptionPDF from '../main/PrescriptionPDF'
import PdfViewer from '../common/pdfViewer'
import { composeSortDescriptors } from '@progress/kendo-data-query'

interface Option {
    value: string,
    id: string,
    data?: any
}
const { confirm } = Modal

export default () => {

    const [prescriptions, setPrescriptions] = useState<any[]>([])
    const [filter, setFilter] = useState(false)
    const [showDrawer, setShowDrawer] = useState(false)
    const [record, setRecord] = useState<any>()
    const [pharmacies, setPharmacies] = useState<Option[]>([])
    const [selectedPharmacy, setSelectedPharmacy] = useState<any>()
    const [textFilter, setTextFilter] = useState('')
    const [editVisible, setEditVisible] = useState(false)
    const [selectedId, setSelectedId] = useState('')

    const { user } = useSelector((state: any) => state)

    useEffect(() => {
        if (user?.role)
            getPrescriptions()
        if (user?.data?.isHost)
            getPharmacies(user?.data?.chain)
    }, [user])

    useEffect(() => {
        const refreshInterval = setInterval(() => getPrescriptions(), 30000);

        return () => clearInterval(refreshInterval);
    }, [user])

    const getPrescriptions = () => {
        var url = ''
        if (user?.role == 'FARMACIA') {
            url = user?.data?.isHost ?
                `prescription/getbychain?name=${user?.data?.chain}` : `prescription/getbypharmacy?id=${user?.data?.pharmacy}`
        } else if (user?.role == 'ADMIN') {
            url = `prescription/gettop?top=${100}`
        }
        API.get(url)
            .then((response: AxiosResponse) => {
                var data = response.data.map((p: any, index: number) => Object.assign(p, { key: index }))
                setPrescriptions([...data])
            })
    }

    const markPrinted = async (id: number) => {
        await API.post(`prescription/markPrinted?id=${id}`)
        getPrescriptions()
    }

    const toggleEdit = () => setEditVisible(!editVisible)

    const openDrawer = (record: any) => {
        console.table(record)
        API.get(`prescription/getbyid?id=${record.data[0].prescriptionId}`)
            .then((response: AxiosResponse) => {
                setRecord(response.data)
                setTimeout(() => toggleDrawerVisibility(), 2000)
            })
            .finally(() => {
                if (user?.data?.isHost || user?.role == 'ADMIN')
                    return

                markPrinted(record.data[0].prescriptionId)
            })
    }
    const toggleDrawerVisibility = () => setShowDrawer(!showDrawer)

    const filterByPrinted = (value: any) => setFilter(value)

    const isNew = (date: any) => moment().diff(moment(date), 'seconds') == 0
    const isOld = (date: any) => moment().diff(moment(date), 'days') >= 1
    const TimeSinceCreated = (record: any) => {
        let val = `${moment().diff(moment(record.data[0].created), 'days')} días`
        return Number.parseInt(val) >= 0 ? Number.parseInt(val) : 0
    }

    const TimeLeft = (record: any) => {
        let val = ''
        if (record.data[0].type == 'Emergencia')
            val = `${3 - moment().diff(moment(record.data[0].created), 'days')} días`
        else
            val = `${30 - moment().diff(moment(record.data[0].created), 'days')} días`

        return Number.parseInt(val) >= 0 ? Number.parseInt(val) : 0
    }

    const canEdit = (printed: boolean) => {
        if (user?.role == 'ADMIN') return true
        return user?.data?.isHost && !printed
    }

    const canPrint = (record: any) => {
        console.log(TimeLeft(record) == 0, record.data[0].printed)
        if (user?.role == 'ADMIN') return true
        if (TimeLeft(record) == 0 || record.data[0].printed) return false
        return true
    }

    const changePharmacy = (pharmacyId: any, prescriptionId: any) =>
        confirm({
            title: 'AVISO',
            content: '¿Está seguro que desea asignar esta receta a otra farmacia?',
            icon: <ExclamationOutlined />,
            onOk: () => API.post('prescription/changepharmacy', { pharmacyId, prescriptionId })
                .then((response: AxiosResponse) => notification.success({
                    message: 'AVISO',
                    description: 'Cambio de farmacia efectuado correctamente'
                }))
                .catch((error: AxiosError) => notification.error({
                    message: 'ERROR',
                    description: 'Ocurrió un error el cambio de farmacia'
                }))
                .finally(() => {
                    toggleEdit()
                    getPrescriptions()
                })
        })

    const getPharmacies = (value: string) => API.post('pharmacy/search', { value, chain: user?.data?.chain || '' })
        .then((response: AxiosResponse) => setPharmacies([...response.data]))
        .catch((error: AxiosError) => console.log(error.response?.data))

    const onPharmacySelected = (value: string, { data }: any) => setSelectedPharmacy({ ...selectedPharmacy, ...data })

    const prescriptionOptions = (record: any) => <Menu>
        {canEdit(record.data[0].printed) && <Menu.Item onClick={() => {
            setSelectedId(record.data[0].prescriptionId)
            toggleEdit()
        }}><EditOutlined /> Cambiar farmacia</Menu.Item>}
        {canPrint(record) && <Menu.Item onClick={() => openDrawer(record)}> <FileDoneOutlined />  Ver receta</Menu.Item>}
        {!canEdit(record.data[0].printed) && !canPrint(record) && <Menu.Item><StopOutlined /> No tiene opciones disponibles</Menu.Item>}
    </Menu>

    const columns = [
        {
            title: '', key: 'actions', dataIndex: 'actions', render: (text: string, record: any) =>
                <Dropdown overlay={prescriptionOptions(record)}><Button type={'link'}>. . .</Button></Dropdown>
        },
        { title: 'Días transcurridos', key: 'restante', dataIndex: 'restante', render: (text: any, record: any) => <Typography.Text strong>{TimeSinceCreated(record)}</Typography.Text> },
        { title: '', key: 'new', dataIndex: 'new', render: (text: any, record: any) => isNew(record.data[0].created) && <ExclamationOutlined style={{ color: 'red' }} /> },
        { title: 'Documento', key: 'id', dataIndex: 'id', render: (text: string, record: any) => record.data[0].prescriptionId.toString().padStart(8, "0") },
        { title: 'Paciente', key: 'patient', dataIndex: 'patient', render: (text: any, record: any) => <Typography.Text strong>{`${record.data[0].patientFirstName} ${record.data[0].patientLastName}`}</Typography.Text> },
        { title: 'Farmacia', key: 'pharmacy', dataIndex: 'pharmacy', render: (text: string, record: any) => <> <Typography.Text strong style={{ wordWrap: 'break-word' }}>{record.data[0].pharmacyName}</Typography.Text> <Typography.Text style={{ wordWrap: 'break-word' }}> - {record.data[0].pharmacyAddressOne} {record.data[0].pharmacyAddressTwo}</Typography.Text> </> },
        { title: 'Fecha', key: 'created', dataIndex: 'created', render: (text: any, record: any) => <Typography.Text>{moment(record.data[0].created).format('DD/MM/YYYY hh:mm A')}</Typography.Text> }
    ]

    return <>
        <PageHeader subTitle={`(${prescriptions?.length})`}
            className="site-page-header"
            title={`Prescripciones - ${user?.data?.chain || user?.role}`} />

        <Row gutter={16}>
            <Col span={4}>
                <Card style={{ cursor: 'pointer' }} onClick={() => filterByPrinted(true)}>
                    <Statistic
                        title="Impresos"
                        value={prescriptions.filter((p: any) => p.data[0].printed == true).length}
                        valueStyle={{ color: '#3f8600' }}
                        prefix={<FileDoneOutlined />}
                    />
                </Card>
            </Col>
            <Col span={4}>
                <Card style={{ cursor: 'pointer' }} onClick={() => filterByPrinted(false)}>
                    <Statistic
                        title="Pendientes"
                        value={prescriptions.filter((p: any) => p.data[0].printed == false).length}
                        valueStyle={{ color: 'goldenrod' }}
                        prefix={<FileExclamationOutlined />}
                    />
                </Card>
            </Col>
            <Col span={4}>
                <Card style={{ cursor: 'pointer' }} onClick={() => filterByPrinted(undefined)}>
                    <Statistic
                        title="Todos"
                        value={prescriptions.length}
                        valueStyle={{ color: '#eeeee' }}
                        prefix={<FolderOpenOutlined />}
                    />
                </Card>
            </Col>
        </Row>
        <br />
        <Row>
            <Col span={8}>
                <Tooltip overlay={'# de prescripción | cédula | nombre | fecha'}>
                    <Input.Search
                        style={{ marginLeft: 10 }}
                        onChange={(e: any) => setTextFilter(e.target.value)}
                        placeholder={'# de prescripción | cédula | nombre | fecha'} />
                </Tooltip>
            </Col>
        </Row>

        <Table
            style={{ padding: 10 }}
            rowClassName={(record: any, index: any) => (isOld(record.data[0].created) && !record.data[0].printed) ? 'table-row-red' : ''}
            dataSource={prescriptions.filter((p: any) => {
                const prescription_id = p.data[0].prescriptionId.toString() ?? ''
                const cedula = p.data[0].cedula ?? ''
                const first_name = p.data[0].patientFirstName ?? ''
                const last_name = p.data[0].patientLastName ?? ''
                if (filter === undefined) return (prescription_id.includes(textFilter)
                    || cedula.includes(textFilter)
                    || (first_name + ' ' + last_name).toLowerCase().includes(textFilter.toLowerCase())
                    || moment(p.data[0].created).format('DD/MM/YYYY hh:mm A').includes(textFilter))
                else {
                    return p.data[0].printed == filter
                        && (prescription_id.includes(textFilter)
                            || cedula.includes(textFilter)
                            || (first_name + ' ' + last_name).toLowerCase().includes(textFilter.toLowerCase())
                            || moment(p.data[0].created).format('DD/MM/YYYY hh:mm A').includes(textFilter))
                }
            })}

            columns={columns}
            expandable={{ rowExpandable: (record: any) => record.data.length > 1 }}
            expandedRowRender={(record: any) => record.data.map((drug: any) => <Typography.Paragraph key={drug.id}>{drug.drugName}</Typography.Paragraph>)}
            size={'small'} />

        {showDrawer && <PdfViewer
            data={record}
            onClose={toggleDrawerVisibility}
            visible={showDrawer} />}

        <Modal
            title={'Cambiar farmacia'}
            onCancel={toggleEdit}
            visible={editVisible}
            width={700}
            footer={[]}>
            <Row>
                <Col span={18}>
                    <AutoComplete
                        suffixIcon={<SearchOutlined />}
                        showArrow
                        style={{ width: '100%' }}
                        placeholder={'Farmacia'}
                        onSelect={onPharmacySelected}
                        onSearch={getPharmacies}
                        options={pharmacies} />
                </Col>
                <Col span={6}>
                    <Button type={'primary'} onClick={() => changePharmacy(selectedPharmacy.id, selectedId)}>Cambiar farmacia</Button>
                </Col>
            </Row>
        </Modal>
    </>
}