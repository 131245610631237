import { Button, Input, List, Modal, notification, Skeleton, Table } from 'antd'
import confirm from 'antd/lib/modal/confirm'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import API from '../../utils/API'

export default (props: any) => {

    if (!props.visible) return <></>

    const [data, setData] = useState(new Array())
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState('')

    useEffect(() => {
        props.visible && props.user && getMyHomologations()
    }, [props.user])

    const columns = [
        {
            title: 'Descripción FPL',
            key: 'descripcionProducto',
            render: (_: any, record: any) => <>
                {record?.nombreProducto} ({record?.concentracion}) {record?.formaDosis} [{record?.packageContentQuantity} / {record?.packageContentSize} - {record?.nombreEmpaque}] {record?.formaAdministracion}  [{record?.substanciaControlada}]
            </>
        },
        {
            title: 'Nombre Carol',
            dataIndex: 'gcName',
            key: 'gcName',

        },
        {
            title: 'Acciones',
            key: 'action',
            render: (_: any, record: any) => <Button danger onClick={() => unHomologate(record.ndc)}>Deshomologar</Button>
        },
    ];

    const getMyHomologations = () => {
        setLoading(true)
        API.get(`carol/myhomologations?username=${props.user}`)
            .then((response: any) => setData(response.data))
            .catch(() => notification.error({ message: 'Error al obtener medicamentos' }))
            .finally(() => setLoading(false))
    }

    const unHomologate = (ndc: any) => confirm({
        onOk: () => API.get(`carol/unHomologate?ndc=${ndc}`)
            .then((response: any) => setData(response.data))
            .catch(() => notification.error({ message: 'Error' }))
            .finally(() => getMyHomologations()),
        title: 'Deshomologar',
        okText: 'Deshomologar'
    })

    return <Modal
        title={'Mis medicamentos homologados'}
        onCancel={props.close}
        visible={props.visible}
        width={1000}
        onOk={props.close}>
        <Input
            style={{ width: '20%' }}
            placeholder='Filtrar'
            value={filter}
            onChange={(e: any) => setFilter(e.target.value)} />
            
        <Skeleton active loading={loading}>
            <Table size='small' dataSource={data ? data?.filter((x: any) => x.descripcionProducto.toLowerCase().includes(filter.toLowerCase())) : []} columns={columns} />
        </Skeleton>
    </Modal>
}