import React, { useState } from 'react'
import { CopyFilled, MailFilled, CloudDownloadOutlined } from '@ant-design/icons'
import copy from 'copy-to-clipboard'

import { Drawer, Button, notification } from 'antd'
import { PDFViewer } from '@react-pdf/renderer'
import PrescriptionPDF from '../main/PrescriptionPDF'
import API from '../../utils/API'
import DownloadPDF from './downloadPdf'


export default (props:any) => {

    const [fileBlob, setFileBlob] = useState<any>()

    const patientName = `${props.data[0].patientFirstName} ${props.data[0].patientLastName}`

    const copyMessageToClipboard = () => {
        copy(`${props.data[0].patientFirstName} ${props.data[0].patientLastName}
Su prescripción #${props.data[0].prescriptionId.toString().padStart(8, "0")} ha sido enviada a ${props.data[0].pharmacyName} ubicada en ${props.data[0].pharmacyAddressOne} ${props.data[0].pharmacyAddressTwo || ''}
Dr. ${props.data[0].prescriberFirstName} ${props.data[0].prescriberLastName}`)

        notification.info({
            message: 'Portapapeles',
            description: 'Se ha copiado un mensaje al portapapeles'
        })
    }

    const sendPrescriptionFile = (e:any) => {
        var file = new File([e.blob], props.data[0].prescriptionId)

        var formData = new FormData();
        formData.append("file", file)

        API.post('prescription/prescriptionFile', formData)
            .then(() => notification.success({
                message: 'AVISO',
                description: 'La receta fue enviada al paciente'
            }))
    }



    return <Drawer
        title={`Prescripción - ${patientName}`}
        placement={'right'}
        onClose={props.onClose}
        closable={true}
        destroyOnClose={true}
        visible={props.visible}
        width={'50%'}>
        <Button.Group size={'small'} style={{ marginBottom: '5px' }}>
            <Button icon={<CopyFilled />} onClick={copyMessageToClipboard}>Mensaje de paciente</Button>
            {(!props.sendOnLoad && props.data[0].patientEmail) && <Button icon={<MailFilled />} onClick={() => sendPrescriptionFile(fileBlob)}>Enviar a paciente</Button>}
            <DownloadPDF blob={fileBlob} filename={`${props.data[0].patientFirstName} ${props.data[0].patientLastName} - ${props.data[0].prescriptionId}.pdf`} />
        </Button.Group>
        <PDFViewer style={{ height: '700px', width: '100%' }}>
            <PrescriptionPDF data={props.data} onRender={(e:any) => {
                setFileBlob(e)
                props.sendOnLoad && sendPrescriptionFile(e)
            }
            } />
        </PDFViewer>
    </Drawer>
}