import React from 'react'
import { Button } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons'
import FileSaver from 'file-saver';

export default (props) => {

    const downloadFile = () => FileSaver.saveAs(props.blob.blob, `${props.filename}`)

    return <Button
        size={'small'}
        onClick={downloadFile}
        icon={<CloudDownloadOutlined />}>Descargar</Button>
}