import React, { useState, useEffect } from 'react'
import { PageHeader, Row, Button, Col, Input, Menu, Dropdown, Table } from 'antd'
import { UserAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import API from '../../utils/API'
import Add from './add'
import CenterModel from '../../models/Center'

export default () => {

    const [centers, setCenters] = useState<any[]>(new Array)
    const [center, setCenter] = useState<CenterModel>(new CenterModel())
    const [filter, setFilter] = useState('')
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        getCenters()
    }, [])

    useEffect(() => {
        getCenters()
    }, [visible])

    const getCenters = async () => {
        const { data } = await API.get(`center/all`)
        setCenters([...data])
    }

    const toggleAdd = () => setVisible(!visible)

    const newCenter = () => {
        setCenter(new CenterModel())
        toggleAdd()
    }
    const editCenter = async (id: Number) => {
        const { data } = await API.get(`center/${id}`)
        setCenter({...data})
        toggleAdd()
    }

    const centerOptions = (record: any) => <Menu>
        <Menu.Item onClick={() => editCenter(record.id)}> <EditOutlined /> Editar </Menu.Item>
    </Menu>

    const columns = [
        {
            title: '', key: 'disabled', dataIndex: 'disabled', width: '50px',
            render: (value: any, record: any) => <Dropdown overlay={centerOptions(record)}>
                <Button type={'link'} size={'small'}>. . .</Button>
            </Dropdown>
        },
        { title: '#', key: 'id', dataIndex: 'id' },
        { title: 'Nombre', key: 'name', dataIndex: 'name' },
        { title: 'Dirección', key: 'address', dataIndex: 'address' },
        { title: 'Teléfono', key: 'phone', dataIndex: 'phone' },
    ]

    return <> <PageHeader title={'Administrador'} />

        <Row
            style={{ marginLeft: 10 }}>
            <Col span={10}>
                <Button onClick={newCenter} icon={<UserAddOutlined />}>Nuevo centro</Button>
                <Input.Search style={{ width: '300px' }} placeholder={'Nombre del centro...'} onChange={(e: any) => setFilter(e.target.value.toLowerCase())} />
            </Col>
        </Row>

        <Table size={'small'}
            columns={columns}
            dataSource={centers.filter((c: any) => c.name.toLowerCase().includes(filter))}
            style={{ margin: 10 }} />

        <Add
            visible={visible}
            close={toggleAdd}
            center={center}
        />
    </>
}