import React from 'react'
import { Modal, Button } from 'antd'

export let IdleTimeoutModal = (logout: any, restoreIdleTimeout: any) => {
    function countDown() {
        let secondsToGo = 30;
        let keepSession = false
        const modal = Modal.warning({
            title: 'Su sesión está por expirar',
            content: `Su sesión va a expirar en ${secondsToGo} segundos.`,
            okText: 'Mantener sesión',
            onOk: () => {
                keepSession = true;
                restoreIdleTimeout()
            }
        });
        const timer = setInterval(() => {
            secondsToGo -= 1;
            modal.update({
                content: `Su sesión va a expirar en ${secondsToGo} segundos.`,
            });
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            if (!keepSession) logout()
            modal.destroy();
        }, secondsToGo * 1000);
    }
    countDown()
}