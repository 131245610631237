import React, { useState } from 'react'
import { MailFilled } from '@ant-design/icons'
import { Drawer, Button, notification } from 'antd'
import { PDFViewer } from '@react-pdf/renderer'
import API from '../../utils/API'
import DownloadPDF from './downloadPdf'
import ClinicalStudyPDF from '../main/ClinicalStudyPDF'

export default (props: any) => {

    const [fileBlob, setFileBlob] = useState<any>()

    const patientName = `${props.data[0]?.patientFirstName} ${props.data[0]?.patientLastName}`

    const sendPrescriptionFile = (e: any) => {
        var file = new File([e.blob], props.data[0].fileId)

        var formData = new FormData();
        formData.append("file", file)

        API.post('exam/examfile', formData)
            .then(() => notification.success({
                message: 'AVISO',
                description: 'El estudio clínico fue enviado al paciente'
            }))
    }

    return <Drawer
        title={`Prescripción - ${patientName}`}
        placement={'right'}
        onClose={props.onClose}
        closable={true}
        destroyOnClose={true}
        visible={props.visible}
        width={'50%'}>
        <Button.Group size={'small'} style={{ marginBottom: '5px' }}>
            {(!props.sendOnLoad && props.data[0]?.patientEmail) && <Button icon={<MailFilled />} onClick={() => sendPrescriptionFile(fileBlob)}>Enviar a paciente</Button>}
            <DownloadPDF blob={fileBlob} filename={`${patientName} - ${props.data[0]?.fileId}.pdf`} />
        </Button.Group>
        <PDFViewer style={{ height: '700px', width: '100%' }}>
            <ClinicalStudyPDF data={props.data} onRender={(e: any) => {
                setFileBlob(e)
                props.sendOnLoad && sendPrescriptionFile(e)
            }} />
        </PDFViewer>
    </Drawer>
}