export default class UserDoctor {
    constructor(obj: any = null) {
        if (obj)
            Object.assign(this, obj)
    }

        id = 0
        userId = 0
        npi = ''
        exequatur = ''
        cedula = ''
        firstName = ''
        lastName = ''
        gender = ''
        specialty = ''
        signature = ''
        stamp = ''
        isFuturo = false
        phone = ''
        email = ''
        centers: any[] = []
        username = ''
        password = ''
        rePassword = ''
        project = ''
}