const SETUSER = 'SET_USER'
const REMOVEUSER = 'REMOVE_USER';
const initialState = null;

export const actionCreators = {
    setUser: (user: any) => ({ type: SETUSER, user}),
    remove: () => ({ type: REMOVEUSER })
};

export const reducer = (state: any, action: any) => {
    state = state || initialState;
    if (action.type === SETUSER)
        return { ...state, ...action.user}

    if(action.type === REMOVEUSER)
        return null

    return state;
};
