export default class UserPharmacy {
    constructor(obj:any = null){
        if (obj)
            Object.assign(this, obj)
    }

    id = 0
    userId = 0
    chain = ''
    pharmacy = ''
    pharmacyText = ''
    phone = ''
    eMail = ''
    username = ''
    password = ''
    rePassword = ''
    isHost = false
}