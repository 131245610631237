import axios from 'axios'

const getUrl = () => {
    const { origin } = window.location
    var path = window.location.pathname.split('/')
    const isEmpty = path.slice(0, 1)[0] == ""
    isEmpty && path.splice(0, 1)
  
    return window.location.pathname == '/' ? `${origin}/api/` : `${origin}/api/`
  }

export default axios.create(
    {
        // baseURL: `https://prescripciones.hfmi.com/api/`,
        // baseURL: `https://localhost:44300/api/`,
        // baseURL: `https://prod.mireceta.com.do/api/`,
        //baseURL: `https://test.mireceta.com.do/api/`,
        baseURL: getUrl(),
        withCredentials: true
    }) 
