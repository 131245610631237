import React, { useState, useEffect } from 'react'
import { Card, Input, Button, Form, notification, Popover, Row, Col } from 'antd'
import { UserOutlined, LockOutlined, LoginOutlined } from '@ant-design/icons'
import LoginModel from '../../models/Login'
import API from '../../utils/API'
import { AxiosResponse, AxiosError } from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import logoalt from '../../assets/images/logoalt.png'
import backgroundImg from '../../assets/images/loginbackground.jpg'

import { actionCreators as UserActions } from '../../store/User'
import ResetPassword from '../../models/ResetPassword'

export default () => {

    const [login, changeLogin] = useState(new LoginModel)
    const [loading, changeLoading] = useState(false)
    const [reset, setReset] = useState(false)
    const [resetModel, setResetModel] = useState<ResetPassword>(new ResetPassword())
    const [forgotUser, setForgotUser] = useState('')

    const [loginform] = Form.useForm()
    const [form] = Form.useForm()

    const { user, router } = useSelector((state: any) => state)
    const dispatch = useDispatch()

    useEffect(() => {
        loginform.setFieldsValue(login)
    }, [login])

    useEffect(() => {
        form.setFieldsValue(resetModel)
    }, [resetModel])

    const navigate = (role: string) => {
        switch (role) {
            case 'FARMACIA': dispatch(push('/pharmacy')); break
            case 'DOCTOR': dispatch(push('/home')); break
            case 'ASISTENTE': dispatch(push('/patients')); break
            case 'PROJECT MANAGER': dispatch(push('/reports')); break
            case 'ADMIN': dispatch(push('/admin')); break
        }
    }

    const changeModel = (e: any) => {
        const { name, value } = e.target
        changeLogin({ ...login, ...{ [name]: value } })
    }

    const submitLogIn = async () => {
        changeLoading(true)

        const loginData = await API.post('login', login)

        switch (loginData.status) {

            case 200: {
                console.log(loginData.status)
                if (loginData.data.reset) {
                    setReset(true)
                    break
                }
                else {
                    dispatch(UserActions.setUser(loginData.data))
                    navigate(loginData.data.role)
                    break;
                }
            }
            case 204: {
                notification.error({
                    message: 'Inicio de sesión',
                    description: 'Credenciales incorrectas'
                })
                break;
            }
            case 209: {
                notification.error({
                    message: 'Inicio de sesión',
                    description: 'Usuario desactivado'
                })
                break;
            }
        }
        changeLoading(false)
    }

    const onValueChange = (e: any) => setResetModel({ ...resetModel, ...{ [e.target.name]: e.target.value } })

    const submitReset = () => {
        const data = { username: login.username, password: resetModel.resetPassword }
        API.post('user/changepassword', data)
            .then((response: AxiosResponse) => {
                notification.success({
                    message: 'Cambio de contraseña',
                    description: 'La contraseña ha sido cambiada con éxito'
                })
                changeLogin(new LoginModel())
                setReset(false)
            })
    }

    const forgot = () => {
        API.get(`user/forgotpassword?userEmail=${forgotUser}`)
            .then((response: AxiosResponse) => {
                if (response.status == 204) {
                    notification.error({
                        message: 'REESTABLECER CONTRASEÑA',
                        description: `No se encontró un usuario relacionado al correo suministrado`
                    })
                }
                else {
                    notification.success({
                        message: 'REESTABLECER CONTRASEÑA',
                        description: `Se ha enviado un correo a ${response.data} con los pasos a seguir.`
                    })
                }
            })
            .catch((error: AxiosError) => notification.error({
                message: 'REESTABLECER CONTRASEÑA',
                description: 'No se pudo reestablecer su contraseña'
            }))
    }

    const layout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 17 },
    };

    const resetLayout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 12 },
    };

    const tailLayout = {
        wrapperCol: { offset: 9, span: 16 },
    };

    const popOverContent = (id: any) => <Row style={{ maxWidth: 400, minWidth: 400 }}>
        <Col span={18}>
            <Input value={forgotUser} onChange={(e: any) => setForgotUser(e.target.value)} placeholder={'Correo electrónico'} />
        </Col>
        <Col span={6}>
            <Button onClick={forgot}>Continuar</Button>
        </Col>
    </Row>

    return <div style={{ height: '100%', backgroundImage: `url(${backgroundImg})` }}>
        <Card
            style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                marginTop: '-200px',
                marginLeft: '-250px',
                width: '500px',
                boxShadow: '2px 3px 10px grey',
                borderRadius: '10px'
            }}
            title={<img src={logoalt} style={{ height: '90px', width: '200' }} />}
            headStyle={{ textAlign: 'center' }}
            bordered={false}>

            {!reset ? <Form
                form={loginform}
                {...layout}
                name={'login'}>
                <Form.Item
                    label={'Usuario'}
                    name={'username'}
                    rules={[{ required: true, message: 'Debe ingresar un nombre de usuario' }]}>
                    <Input
                        name={'username'}
                        value={login.username}
                        prefix={<UserOutlined />}
                        onChange={changeModel} />
                </Form.Item>

                <Form.Item
                    label={'Contraseña'}
                    name={'password'}
                    rules={[{ required: true, message: 'Debe ingresar su contraseña' }]}>
                    <Input.Password
                        name={'password'}
                        value={login.password}
                        prefix={<LockOutlined />}
                        onChange={changeModel} />
                </Form.Item>

                <Form.Item
                    {...tailLayout}
                    name={'forgot'}>
                    <Button
                        loading={loading}
                        icon={<LoginOutlined />}
                        onClick={submitLogIn}>Ingresar al sistema</Button>
                </Form.Item>

                <Form.Item
                    {...tailLayout}
                    name={'forgot'}>
                    <Popover
                        trigger={['click']}
                        placement={'bottom'}
                        title={'Olvidé mi contraseña'}
                        content={popOverContent}>
                        <Button type={'link'}>Olvidé mi contraseña</Button>
                    </Popover>
                </Form.Item>
            </Form> : <Form
                {...resetLayout}
                title={user?.username || ''}
                name={'pharmacy'}
                onFinish={submitReset}
                form={form}>
                <Form.Item name="resetPassword" label="Nueva contraseña" rules={[{ required: true }]}>
                    <Input.Password name={'resetPassword'} onChange={onValueChange} />
                </Form.Item>
                <Form.Item name="rePassword" label="Repetir contraseña"
                    rules={[{ required: true },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('resetPassword') === value)
                                return Promise.resolve();
                            return Promise.reject('Las contraseñas no coinciden');
                        },
                    })]}>
                    <Input.Password name={'rePassword'} onChange={onValueChange} />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button loading={loading} htmlType={'submit'} type={'primary'}>Reiniciar contraseña</Button>
                </Form.Item>
            </Form>}

        </Card>
    </div>
}