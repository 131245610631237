import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import moment from 'moment'
import { Modal, Button, Form, Input, Select, DatePicker, Typography, notification } from 'antd'
import { CloseOutlined, SaveOutlined } from '@ant-design/icons'
import PatientModel from '../../models/Patient'
import API from '../../utils/API'
import { isCedulaValid } from '../../utils/Helper'
import FormItem from 'antd/lib/form/FormItem'

export default (props: any) => {

    const [patient, changePatient] = useState(new PatientModel)
    const [availableDoctors, setAvailableDoctors] = useState(new Array)
    const [form] = Form.useForm();

    const { user } = useSelector((state: any) => state)

    const { Option } = Select

    useEffect(() => {
        getRelatedDoctors()
        changePatient(props.patient)
        form.setFieldsValue(props.patient)
    }, [props.visible])

    const layout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 },
    };

    const changeInput = ({ target }: any) => {
        const { name, value } = target
        let _patient = { ...patient, ...{ [name]: value.toUpperCase() } }
        changePatient(_patient)
        form.setFieldsValue(_patient)
    }

    const genderSelected = (value: string) => {
        let _patient = { ...patient, ...{ gender: value } }
        changePatient(_patient)
        form.setFieldsValue(_patient)
    }

    const doctorSelected = (value: number) => {
        let _patient = { ...patient, ...{ doctor: value } }
        changePatient(_patient)
        form.setFieldsValue(_patient)
    }

    const changeDate = (date: any, dateString: string) => {
        console.log(date, dateString)
        let _patient = { ...patient, ...{ dob: dateString } }
        console.log(_patient)
        changePatient(_patient)
        form.setFieldsValue(_patient)
    }

    const getAge = () => {
        var diff_ms = Date.now() - new Date(patient.dob).getTime();
        var age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }


    const send = () => {
        if (isAssistant && !patient.doctor) {
            notification.error({ message: 'Debe seleccionar un doctor' })
            return
        }
        
        API.post(props.patient ? 'patient/update' : 'patient', { ...patient, ...{ doctor: isAssistant ? patient.doctor : user.data?.id } })
            .then(() => {
                notification.success({
                    message: 'PACIENTE',
                    description: 'Paciente guardado con éxito'
                })
                close()
            })
            .catch(() => notification.error({
                message: 'PACIENTE',
                description: 'Ocurrió un error al guardar el paciente'
            }))
            .finally(() => props.refresh())
    }

    const close = () => {
        changePatient(new PatientModel)
        form.setFieldsValue(new PatientModel)
        props.close()
    }

    const getRelatedDoctors = () => API.get(`user/getRelatedDoctors?assistantId=${user?.data?.id}`)
        .then((respose: any) => setAvailableDoctors([...respose.data]))

    const isAssistant = user?.role == "ASISTENTE"

    return <Modal
        visible={props.visible}
        onCancel={close}
        title={'Nuevo paciente'}
        destroyOnClose={true}
        footer={[
            <Button key={1} onClick={close} icon={<CloseOutlined />}>Cancelar</Button>,
            <Button key={2} onClick={send} icon={<SaveOutlined />}>{props.patient ? 'Actualizar' : 'Guardar'}</Button>
        ]}>
        <Form {...layout} form={form}>
            <Form.Item
                label={'Nombres'}
                name={'firstName'}
                rules={[{ required: true, message: 'Nombre requerido' }]}>
                <Input
                    name={'firstName'}
                    value={patient.firstName}
                    onChange={changeInput} />
            </Form.Item>

            <Form.Item
                label={'Apellidos'}
                name={'lastName'}
                rules={[{ required: true, message: 'Apellido requerido' }]}>
                <Input
                    name={'lastName'}
                    value={patient.lastName}
                    onChange={changeInput} />
            </Form.Item>

            <Form.Item
                label={'Sexo'}
                name={'gender'}
                rules={[{ required: true, message: 'Sexo requerido' }]}>
                <Select
                    onSelect={genderSelected}
                    value={patient.gender}>
                    <Option key={1} value={'M'}>MASCULINO</Option>
                    <Option key={2} value={'F'}>FEMENINO</Option>
                </Select>
            </Form.Item>

            <Form.Item
                label={'Nacimiento'}
                name={'dob'}
                rules={[{ required: true, message: 'Fecha de nacimiento obligatoria' }]}>
                <DatePicker
                    format={'MM/DD/YYYY'}
                    allowClear={false}
                    value={moment(patient.dob)}
                    onChange={changeDate} />
                <Typography.Text style={{ marginLeft: '10px' }} strong>{`${getAge()} Años`}</Typography.Text>
            </Form.Item>

            {getAge() > 18 && <Form.Item
                label={'Cédula'}
                name={'cedula'}
                rules={[{ required: true, message: 'Debe ingresar un número de cédula' }]}>
                <Input
                    name={'cedula'}
                    value={patient.cedula}
                    maxLength={11}
                    onChange={changeInput} />
            </Form.Item>}

            <Form.Item
                label={'Teléfono'}
                name={'phone'}
                rules={[{ required: true, message: 'Debe ingresar un telefono' }]}>
                <Input
                    name={'phone'}
                    value={patient.phone}
                    maxLength={10}
                    onChange={changeInput} />
            </Form.Item>

            <Form.Item
                label={'E-mail'}
                name={'email'}
                rules={[{ required: true, message: 'Debe ingresar un correo electrónico' }]}>
                <Input
                    name={'email'}
                    value={patient.email}
                    onChange={changeInput} />
            </Form.Item>

            <Form.Item
                label={'Dirección'}
                name={'address'}
                rules={[{ required: true, message: 'Debe ingresar una dirección' }]}>
                <Input
                    name={'address'}
                    value={patient.address}
                    onChange={changeInput} />
            </Form.Item>

            <Form.Item
                label={'Aseguradora'}
                name={'insurance'}>
                <Input
                    name={'insurance'}
                    value={patient.insurance}
                    onChange={changeInput} />
            </Form.Item>

            {isAssistant ?
                <FormItem
                    label={'Doctor'}
                    name={'doctor'}>
                    <Select
                        onSelect={doctorSelected}
                        value={patient.doctor}>
                        {availableDoctors.map((doctor: any, idx: number) => <Option
                            key={idx}
                            value={doctor.id}>{`${doctor.firstName} ${doctor.lastName}`}</Option>)}
                    </Select>
                </FormItem> : <></>}
        </Form>
    </Modal>
}