import React, { useState, useEffect } from 'react'
import { PageHeader, Modal, Menu, Dropdown, Button, Row, Col, Table, Tag, Badge, Input, notification } from 'antd'
import { UserAddOutlined, EditOutlined, UserDeleteOutlined } from '@ant-design/icons'
import AddUser from './users/addDoctor'
import API from '../../utils/API'
import { AxiosResponse, AxiosError } from 'axios'
import moment from 'moment'
import UserModel from '../../models/UserDoctor'
import UserPharmacy from '../../models/UserPharmacy'
import AddPharmachy from './users/addPharmachy'
import AddAssistant from './users/addAssistant'
import UserAssistant from '../../models/UserAssistant'
import ProjectManager from '../../models/ProjectManager'
import AddProjectManager from './users/AddProjectManager'

const { confirm } = Modal

export const AdminPanel = () => {

    const [userAddVisible, setUserAddVisible] = useState(false)
    const [userPharmacyVisible, setUserPharmacyAddVisible] = useState(false)
    const [userAssistantVisible, setUserAssistantVisible] = useState(false)
    const [userProjectManagerVisible, setUserProjectManagerVisible] = useState(false)
    const [users, setUsers] = useState(new Array)
    const [editUser, setEditUser] = useState<any>()
    const [editPharmacy, setEditPharmacy] = useState<any>()
    const [editAssistant, setEditAssistant] = useState<any>()
    const [editProjectManager, setEditProjectManager] = useState<any>()
    const [filter, setFilter] = useState('')

    useEffect(() => {
        getAllUsers()
    }, [userAddVisible == false, userPharmacyVisible == false])

    const roleColor = (value: string) => {
        switch (value) {
            case 'DOCTOR': return 'geekblue'
            case 'FARMACIA': return 'green'
            case 'ASISTENTE': return 'purple'
            case 'PROJECT MANAGER': return 'pink'
            case 'ADMIN': return 'gold'
        }
    }

    const toggleUserAddVisible = () => setUserAddVisible(!userAddVisible)
    const toggleUserPharmacyVisible = () => setUserPharmacyAddVisible(!userPharmacyVisible)
    const toggleUserAssistantVisible = () => setUserAssistantVisible(!userAssistantVisible)
    const toggleUserProjectManagerVisible = () => setUserProjectManagerVisible(!userProjectManagerVisible)

    const getAllUsers = () => API.get('user/all')
        .then((response: AxiosResponse) => setUsers([...response.data])
        )
        .catch(() => notification.error({
            message: 'ERROR',
            description: 'Ocurrió un error al obtener usuarios'
        }))

    const getUser = (id: any, role: string) => API.get(`user/get?id=${id}`)
        .then((response: AxiosResponse) => {
            switch (role) {
                case 'DOCTOR': {
                    setEditUser(response.data)
                    toggleUserAddVisible()
                    break;
                }
                case 'FARMACIA': {
                    setEditPharmacy(response.data)
                    toggleUserPharmacyVisible()
                    break;
                }
                case 'ASISTENTE': {
                    setEditAssistant(response.data)
                    toggleUserAssistantVisible()
                    break;
                }
                case 'PROJECT MANAGER': {
                    setEditProjectManager(response.data)
                    toggleUserProjectManagerVisible()
                    break;
                }
            }
        })
        .catch((error: AxiosError) => { })

    const newUser = () => {
        setEditUser(new UserModel())
        toggleUserAddVisible()
    }

    const newPharmacy = () => {
        setEditPharmacy(new UserPharmacy())
        toggleUserPharmacyVisible()
    }

    const newAssistant = () => {
        setEditAssistant(new UserAssistant())
        toggleUserAssistantVisible()
    }

    const newProjectManager = () => {
        setEditProjectManager(new ProjectManager())
        toggleUserProjectManagerVisible()
    }

    const toggleDisable = (id: number) => confirm({
        title: 'AVISO',
        content: '¿Está seguro que desea cambiar el estado de este usuario?',
        onOk: () => {
            API.post(`user/toggledisable?id=${id}`)
                .then(() => notification.success({
                    message: 'AVISO',
                    description: 'Operación realizada con éxito'
                }))
                .finally(() => getAllUsers())
        }
    })

    const userOptions = (record: any) => <Menu>
        {record.role != 'ADMIN' && <Menu.Item onClick={() => getUser(record.id, record.role)}> <EditOutlined /> Editar </Menu.Item>}
        <Menu.Item onClick={() => toggleDisable(record.id)}> <UserDeleteOutlined /> {record.disabled ? 'Habilitar' : 'Desactivar'} </Menu.Item>
    </Menu>

    const columns = [
        {
            title: '', key: 'disabled', dataIndex: 'disabled', width: '50px',
            render: (value: any, record: any) => <Dropdown overlay={userOptions(record)}>
                <Button type={'link'} size={'small'}>. . .</Button>
            </Dropdown>
        },
        {
            title: '', key: 'disabled', dataIndex: 'disabled', width: '50px',
            render: (value: boolean) => <Badge color={value ? 'red' : 'green'}>{' '}</Badge>
        },
        { title: '#', key: 'id', dataIndex: 'id' },
        { title: 'Usuario', key: 'username', dataIndex: 'username' },
        {
            title: 'Rol', key: 'role', dataIndex: 'role',
            render: (value: string) => <Tag color={roleColor(value)}>{value}</Tag>
        },
        {
            title: 'Fecha de creación', key: 'created', dataIndex: 'created',
            render: (value: string) => moment(value).format('DD/MM/YYYY')
        },
    ]

    const menu = () => <Menu>
        <Menu.Item onClick={newUser} key={0}>Doctor</Menu.Item>
        <Menu.Item onClick={newAssistant} key={1}>Asistente</Menu.Item>
        <Menu.Item onClick={newPharmacy} key={2}>Farmacia</Menu.Item>
        <Menu.Item onClick={newProjectManager} key={3}>Project Manager</Menu.Item>
    </Menu>

    return <>
        <PageHeader title={'Administrador'} />
        <Row style={{ marginLeft: 10 }}>
            <Col span={4}>
                <Dropdown
                    overlay={menu}>
                    <Button icon={<UserAddOutlined />}>Nuevo usuario</Button>
                </Dropdown>
            </Col>
        </Row>
        <br />
        <Row
            style={{ marginLeft: 10 }}>
            <Col>
                <Input.Search placeholder={'Nombre de usuario...'} onChange={(e: any) => setFilter(e.target.value.toLowerCase())} />
            </Col>
        </Row>

        <Table size={'small'}
            columns={columns}
            dataSource={users.filter((u: any) => u.username.toLowerCase().includes(filter))}
            style={{ margin: 10 }} />

        <AddPharmachy
            user={editPharmacy}
            visible={userPharmacyVisible}
            close={toggleUserPharmacyVisible} />

        <AddUser
            user={editUser}
            visible={userAddVisible}
            close={toggleUserAddVisible} />

        <AddAssistant
            user={editAssistant}
            visible={userAssistantVisible}
            close={toggleUserAssistantVisible} />

        <AddProjectManager
            user={editProjectManager}
            visible={userProjectManagerVisible}
            close={toggleUserProjectManagerVisible}
        />
    </>
}