import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Checkbox, Button, Upload, notification, Row, Col, Select, Dropdown, Menu, AutoComplete } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import UserModel from '../../../models/UserDoctor'
import API from '../../../utils/API'
import { AxiosError, AxiosResponse } from 'axios'
import UserPharmacy from '../../../models/UserPharmacy'
import _ from 'lodash'

interface Option {
    value: string,
    id: string,
    data?: any
}

export default (props: any) => {

    const [user, setUser] = useState<UserPharmacy>(new UserPharmacy)
    const [chains, setChains] = useState<Option[]>()
    const [pharmacies, setPharmacies] = useState<any[]>([])
    const [pharmacyForm] = Form.useForm()

    useEffect(() => {
        if (props.user?.id) {
            getSelectedPharmacyName()
        }
        else {
            pharmacyForm.setFieldsValue(new UserPharmacy())
            setUser(new UserPharmacy())
        }
    }, [props.visible])

    useEffect(() => {
        pharmacyForm.setFieldsValue(user)
    }, [user])

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    const tailLayout = {
        wrapperCol: { offset: 6, span: 16 },
    };

    const getSelectedPharmacyName = () => {
        API.get(`pharmacy/get?id=${props.user.pharmacy}`)
            .then((response: AxiosResponse) => {
                pharmacyForm.setFieldsValue({ ...props.user, ...{ pharmacyText: response.data } })
                setUser({ ...props.user, ...{ pharmacyText: response.data } })
            })
    }

    const getChains = (value: string) => {
        API.get(`pharmacy/chainsearch?name=${value}`)
            .then((response: AxiosResponse) => setChains([...response.data]))
            .catch((error: AxiosError) => console.log(error.response?.data))
    }

    const getPharmacies = (value: string, option: any) => {
        setUser({ ...user, ...{ chain: value } })
        API.get(`pharmacy/pharmacysearch?chain=${value}`)
            .then((response: AxiosResponse) => setPharmacies(response.data))
    }

    const send = () => {
        API.post(props.user?.id ? 'user/updatePharmacy' : 'user/addpharmacy', user)
            .then((response: AxiosResponse) => {
                notification.success({
                    message: 'AVISO',
                    description: 'Usuario guardado satisfactoriamente'
                })
                props.close()
            })
            .catch((error: AxiosError) => console.log(error.response?.data))
    }

    const onChange = (e: any) => setUser({ ...user, ...{ [e.target.name]: e.target.value } })
    const onCheckChange = (e: any) => setUser({ ...user, ...{ [e.target.name]: e.target.checked } })
    const onPharmacySelected = (e: any) => setUser({ ...user, ...{ pharmacy: e.key, pharmacyText: e.item.props.children } })

    const pharmacyMenu = () => <Menu>
        {pharmacies.map((pharmacy: any) => <Menu.Item onClick={onPharmacySelected} key={pharmacy.id} id={pharmacy.id}>{pharmacy.name}</Menu.Item>)}
    </Menu>

    return <Modal
        visible={props.visible}
        onCancel={props.close}
        footer={[]}
        title={'Farmacia'}>
        <>
            <Form
                {...layout}
                form={pharmacyForm}
                name={'pharmacy'}
                onFinish={send}>
                <Form.Item name="chain" label="Cadena" rules={[{ required: true }]}>
                    <AutoComplete
                        onSearch={(value: string) => getChains(value)}
                        onSelect={(value: string, option: any) => getPharmacies(value, option)}
                        options={chains} />
                </Form.Item>
                <Form.Item name="pharmacy" label="Farmacia" rules={[{ required: true }]}>
                    <Dropdown
                        overlayStyle={{ maxHeight: 300, overflowY: 'auto', overflowX: 'hidden' }}
                        overlay={pharmacyMenu}>
                        <Button>{user.pharmacyText || 'Seleccione una farmacia'}</Button>
                    </Dropdown>
                </Form.Item>
                <Form.Item name="phone" label="Teléfono">
                    <Input name={"phone"} onChange={onChange} />
                </Form.Item>
                <Form.Item name="email" label="E-Mail" rules={[
                    { required: true },
                    () => ({
                        async validator(rule, value) {

                            let { data } = await API.post('user/emailexists', { username: user.userId, field: value.trim() })
                            if (data)
                                return Promise.reject('Este correo ya existe')
                            return Promise.resolve()
                        },
                    })]}>
                    <Input name={"email"} onChange={onChange} />
                </Form.Item>
                <Form.Item name="isHost" label="Host" rules={[{ required: true }]}>
                    <Checkbox checked={user.isHost} name={"isHost"} onChange={onCheckChange} />
                </Form.Item>
                {!props.user?.id ? <>
                    <Form.Item name="username" label="Usuario" rules={[
                        { required: true },
                        () => ({
                            async validator(rule, value) {

                                let { data } = await API.get(`user/userexists?username=${value.trim()}`)
                                if (data)
                                    return Promise.reject('Este usuario ya existe')
                                return Promise.resolve()
                            },
                        })]}>
                        <Input name={"username"} onChange={onChange} />
                    </Form.Item>
                </> : <></>}
                <Form.Item {...tailLayout}>
                    <Button
                        type={'primary'}
                        htmlType={'submit'}>{props.user?.id ? 'Actualizar' : 'Guardar'}</Button>
                </Form.Item>
            </Form>
        </>
    </Modal>
}