import React from 'react';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { CSVLink } from 'react-csv';
import { orderBy } from '@progress/kendo-data-query';
import '@progress/kendo-theme-default'
import _ from 'lodash'

export default class KendoGrid extends React.Component<any, any> {
    state = {
        skip: 0,
        pageSize: this.props.pageSize,
        selectedField: null,
        data: [] as any,
        show: false,
        sort: [],
    }

    lastSelectedIndex = 0
    offSet = {} as any;

    Columns = () => {
        return this.props.columns.map((column: any) => {
            if(column){
                return <GridColumn
                key={column.field}
                field={column.field}
                title={column.title}
                width={column.width}
                format={column.format}
                footerCell={column.footer}
                cell={column.cell} />
            }
        })
    }

    rowRender = (trElement: any, dataItem: any) => {
        const trProps = {
            ...trElement.props,
            onContextMenu: (e: any) => {
                e.preventDefault()
                if (typeof (this.props.changeRightClicked) === 'function') (
                    this.props.changeRightClicked(dataItem)
                )
            },
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    handleContextMenu = (e: any) => {
        e.preventDefault();
        this.offSet = { left: e.clientX, top: e.clientY };
        this.setState({ show: true });
    }

    // componentDidUpdate(prev: any) {
    //     console.log(!_.isEqual(prev.data, this.props.data))
    //     if (_.isEqual(prev.data, this.props.data)){
    //         var _data = this.props.data
    //         _data = this.props.data.map((item: any) => Object.assign({ selected: false }, item))
    //         this.setState({ data: _data, skip: 0 })
    //     }
    // }
    
    componentWillReceiveProps(next: any){
        if (!_.isEqual(next.data, this.props.data)){
            var _data = next.data
            _data = next.data.map((item: any) => Object.assign({ selected: false }, item))
            this.setState({ data: _data })
        }
    }

    componentDidMount() {
        this.setState({ data: this.props.data })
        document.addEventListener('click', () => {
            this.setState({ show: false });
        })
    }

    onPageChange = (event: any) => {
        this.setState({ skip: event.page.skip, pageSize: event.page.take })
    }

    selectionChange = (event: any) => {
        event.dataItem.selected = !event.dataItem.selected;
        this.forceUpdate();
    }

    rowClick = (event: any) => {
        let last = this.lastSelectedIndex;
        const current = this.state.data.findIndex((dataItem: any) => dataItem === event.dataItem);

        if (!event.nativeEvent.shiftKey) {
            this.lastSelectedIndex = last = current;
        }

        if (!event.nativeEvent.ctrlKey) {
            this.state.data.forEach((item: any) => item.selected = false);
        }
        const select = !event.dataItem.selected;
        for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
            this.state.data[i].selected = select;
        }
        this.forceUpdate();
        this.props.onRowClick(event.dataItem)
    }

    headerSelectionChange = (event: any) => {
        const checked = event.syntheticEvent.target.checked;
        this.state.data.forEach((item: any) => item.selected = checked);
        this.forceUpdate();
    }

    _export: any;

    export = () => {
        this._export.save();
    }

    render() {
        return (
            <div style={{ height: 'inherit', overflow: 'hidden' }} onContextMenu={this.props.onContextMenu}>
                <ExcelExport
                    data={this.props.data}
                    ref={(exporter) => { this._export = exporter; }}>
                    <Grid
                        style={{ height: '100%', fontSize: '10px', display: this.props.hidden ? 'none' : 'flex' }}
                        data={this.state.data.slice(this.state.skip, this.state.pageSize + this.state.skip)}
                        skip={this.state.skip}
                        pageSize={this.state.pageSize}
                        total={this.props.data.length}
                        onPageChange={this.onPageChange}
                        onDataStateChange={(e) => { this.setState(e.data) }}
                        pageable={true}
                        sortable={true}
                        sort={this.state.sort}
                        resizable={true}
                        onSortChange={(e: any) => this.setState({ sort: e.sort, data: orderBy(this.state.data, e.sort) })}
                        rowRender={(trElement: any, dataItem: any) => this.rowRender(trElement, dataItem)}
                        onRowClick={this.props.selectable ? this.rowClick : this.props.onRowClick}
                        selectedField={'selected'}>
                        {!this.props.hideExport && <GridToolbar>
                            <button
                                title="Export to Excel"
                                onClick={this.export}>
                                Export to Excel
                        </button>
                            <button>
                                <CSVLink data={this.props.data}>Export to CSV</CSVLink>
                            </button>
                        </GridToolbar>}
                        {this.Columns()}
                    </Grid>
                </ExcelExport>

                <Popup show={this.state.show} offset={this.offSet}>
                    <Menu vertical={true} style={{ display: 'inline-block' }}>
                        <MenuItem text="Resend Batch Errors" />
                        <MenuItem text="Download File" />
                        <MenuItem text="Resend Batch All" />
                        <MenuItem text="Reset Batch" />
                        <MenuItem text="Delete Batch" />
                    </Menu>
                </Popup>
            </div>
        );
    }
}