import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { PageHeader, Card, Select, Divider, Typography, Button, AutoComplete, Row, Col, Popover, Input, notification } from 'antd'
import { SaveFilled, SearchOutlined, ClearOutlined, StarOutlined } from '@ant-design/icons'
import logoalt from '../../assets/images/logoalt.png'
import API from '../../utils/API'
import { AxiosResponse, AxiosError } from 'axios'
import PatientModel from '../../models/Patient'
import StudyPdfViewer from '../common/studyPdfViewer'

export default (props: any) => {

    const { user } = useSelector((state: any) => state)

    const [type, setType] = useState(1)
    const [patients, setPatients] = useState<any[]>(new Array)
    const [studies, setStudies] = useState<any[]>(new Array)
    const [auxiliaries, setAuxiliaries] = useState<any[]>(new Array)
    const [selected, setSelected] = useState<Array<number>>(new Array)
    const [packageName, setPackageName] = useState('')
    const [patient, setPatient] = useState<PatientModel>(new PatientModel())
    const [saved, setSaved] = useState<any[]>(new Array)
    const [common, setCommon] = useState<any[]>(new Array)
    const [loading, setLoading] = useState(false)
    const [documentVisile, setVisible] = useState(false)

    useEffect(() => {
        getCommonStudies()
        if (type == 1)
            getLaboratoryStudies()
    }, [])

    useEffect(() => {
        getCommonStudies()
        clear()
        if (type && type == 1)
            getLaboratoryStudies()
        else if (type == 2)
            getAuxiliaryStudies()
    }, [type])

    const toggleDrawerVisibility = () => setVisible(!documentVisile)

    const changeType = (value: number) => setType(value)

    const getLaboratoryStudies = () => API.get('exam/GetLaboratoryStudies')
        .then((response: AxiosResponse) => setStudies([...response.data]))
        .catch((err: AxiosError) => console.log(err.message))

    const getAuxiliaryStudies = () => API.get('exam/GetAuxiliaryStudies')
        .then((response: AxiosResponse) => setAuxiliaries([...response.data]))
        .catch((err: AxiosError) => console.log(err.message))

    const getPatients = async (value: string) => {
        const { data } = await API.post('patient/search', { value, doctor: user?.data?.id })
        setPatients([...data])
    }

    const onPatientSelected = async (value: string, option: any) => {
        const { data } = await API.post(`patient/get`, { id: option.id })
        setPatient(data)
    }

    const clear = () => {
        setSaved([...[]])
        setSelected([...[]])
    }

    const save = async () => {
        setLoading(true) 
        const obj = {
            patient: patient.id,
            exams: selected,
            doctor: user?.data?.id,
        }

        const { data } = await API.post('exam/save', obj)
        setSaved([...data])
        setSelected([...[]])
        setLoading(false)
        setVisible(true)
    }

    const saveCommonStudy = () => {

        if (!selected.length) {
            notification.warning({
                message: 'AVISO',
                description: 'Debe seleccionar los estudios'
            })
        }
        const data = {
            studies: selected,
            name: packageName,
            userId: user?.data?.id,
            type
        }

        API.post('exam/savecommonstudies', data)
            .then(() => {
                getCommonStudies()
                setPackageName('')
                notification.success({
                    message: 'AVISO',
                    description: 'Paquete favorito guardado con éxito'
                })
            })
            .catch(() => notification.error({
                message: 'ERROR',
                description: 'Error al guardar paquete'
            }))
    }

    const getCommonStudies = () => {
        API.get(`exam/getcommonstudies?userId=${user?.data?.id}&type=${type}`)
            .then((response: AxiosResponse) => setCommon([...response.data]))
            .catch(() => notification.error({
                message: 'ERROR',
                description: 'Ha ocurrido un error obteniendo paquetes'
            }))
    }

    const setFromCommon = (key: string) => {
        const { data } = common.find(x => x.key == key)
        setSelected([...data.map((x: any) => x.examId)])
    }

    return <>
        <PageHeader
            className="site-page-header"
            title={<img src={logoalt} style={{ height: '50px' }} />} />

        <Card style={{ margin: '1%' }}>
            <Typography.Text strong>Tipo de examen</Typography.Text> <br />
            <Select size={'large'} value={type} onSelect={changeType} style={{ width: '300px' }}>
                <Select.Option value={1}>Laboratorio</Select.Option>
                <Select.Option value={2}>Examenes Auxiliares</Select.Option>
            </Select>

            <Divider />
            <AutoComplete
                suffixIcon={<SearchOutlined />}
                autoClearSearchValue={true}
                showArrow
                size={'large'}
                style={{ width: '30%' }}
                placeholder={'Paciente'}
                onSelect={onPatientSelected}
                onSearch={getPatients}
                options={patients} />

            <br />
            {patient.id ? <Typography.Text strong>{`${patient.fullName} (${patient.gender}) / Edad: ${patient.age} / e-Mail: ${patient.email}`}</Typography.Text> : <></>}
            <Divider />

            <Typography.Text strong>Seleccione los examenes</Typography.Text>

            <Row>
                <Col span={20}>
                    <Select
                        mode="multiple"
                        size={'large'}
                        placeholder="Examenes..."
                        value={selected}
                        onChange={(e: any) => setSelected(e)}
                        filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: '100%' }}>
                        {type == 1 && studies.map((study: any) => <Select.Option key={study.id} value={study.id}>{study.examName}</Select.Option>)}
                        {type == 2 && auxiliaries.map((study: any) => <Select.Option key={study.id} value={study.id}>{study.examName}</Select.Option>)}
                    </Select>
                </Col>
                <Col span={4}>
                    <Popover
                        content={<Input
                            value={packageName}
                            onChange={(e: any) => setPackageName(e.target.value)} suffix={<Button type={'primary'} onClick={saveCommonStudy}>Guardar</Button>} />}
                        title={'Nombre del paquete'}
                        trigger={'click'}>
                        <Button size={'large'} type={'primary'} icon={<StarOutlined />}>AGREGAR A FAVORITO</Button>
                    </Popover>

                </Col>
            </Row>


            <Divider />

            <Card title="Favoritos">
                {common.map((c: any) => <Card.Grid style={{ width: '25%', textAlign: 'center' }}>
                    {`${c.key} | ${c.data.length} Estudios`} <br />
                    <Button onClick={() => setFromCommon(c.key)}>Seleccionar</Button>
                </Card.Grid>)}
            </Card>

            <Button.Group>
                <Button loading={loading} disabled={!selected.length || !patient.id} size={'large'} type={'primary'} icon={<SaveFilled />} onClick={save}>GUARDAR</Button>
                <Button type={'danger'} size={'large'} icon={<ClearOutlined />} onClick={clear}>LIMPIAR</Button>
            </Button.Group>

        </Card>

        <StudyPdfViewer
            onClose={toggleDrawerVisibility}
            visible={documentVisile}
            data={saved}
            sendOnLoad={true} />
    </>
}