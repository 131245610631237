import moment from 'moment'

export default class ReportFilter {
    constructor(obj: any = null) {
        if (obj)
            Object.assign(this, obj)
    }

    chainName = ''
    pharmacyCode = 0
    dateStart = moment().format('MM/DD/YYYY')
    dateEnd = moment().format('MM/DD/YYYY')
    type = ''
    doctorCode = 0
    chain = ''
    pharmacy = ''
    doctor = ''
    patient = 0
    project = ''
}