import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Route } from 'react-router-dom';
import { Layout, Menu, Typography } from 'antd';
import { FileFilled, UserOutlined, HeartFilled, KeyOutlined, LogoutOutlined, FileSearchOutlined, DatabaseOutlined, ExperimentFilled, TransactionOutlined } from '@ant-design/icons'
import Main from './main/main'
import Center from './center'
import Exam from './exam/exam'
import PharmacyList from './pharmacy'
import PatientList from './patient';
import Reports from './reports'
import { AdminPanel } from './admin'
import { push } from 'react-router-redux';
import login from './login/login';
import API from '../utils/API';
import { IdleTimeoutModal } from './idleModal'
import { actionCreators as UserActions } from '../store/User'
import logomini from '../assets/images/logomini.png'
import banner from '../assets/images/banner.jpg'
import Homologation from './homologation';

const { Content, Sider } = Layout

export default () => {

    const [idleTime, setIdleout] = useState(60 * 60 * 1000)
    const [showSider, toggleSider] = useState(false)

    const { user, router } = useSelector((state: any) => state)
    const dispatch = useDispatch()

    useEffect(() => {
        document.addEventListener('mousedown', restoreIdleTimeout)
    }, [])

    var timer = {} as NodeJS.Timeout

    const beginSessionTimeout = () => timer = setTimeout(() => IdleTimeoutModal(logout, restoreIdleTimeout), idleTime)

    const restoreIdleTimeout = () => {
        clearTimeout(timer)
        beginSessionTimeout()
    }

    const toggleSiderVisibility = () => toggleSider(!showSider)

    const logout = async () => {
        await API.post('logout')
        dispatch(UserActions.remove())
        dispatch(push('/login'))
    }

    const isDoctor = user?.role === 'DOCTOR'
    const isPharmacy = user?.role === 'FARMACIA'
    const isAdmin = user?.role === 'ADMIN'
    const isAssistant = user?.role == 'ASISTENTE'
    const isProjectManager = user?.role == 'PROJECT MANAGER'

    if (!user && router?.location?.pathname !== '/login')
        dispatch(push('/login'))

    return <>
        <Layout style={{ height: '100%' }}>
            {(user !== null) && <Sider collapsible collapsed={showSider} onCollapse={toggleSiderVisibility}>
                <div style={{ textAlign: 'center' }}>
                    <img src={logomini} className="logo" />
                </div>

                <Menu theme={'dark'} mode={'inline'}>
                    <Menu.Item key="0">
                        <Typography.Text strong style={{ color: 'white' }}>{user.username}</Typography.Text>
                    </Menu.Item>
                    {isDoctor && <Menu.Item key="1" onClick={() => dispatch(push('/home'))}>
                        <FileFilled />
                        <span>Prescripción</span>
                    </Menu.Item>}
                    {isDoctor && <Menu.Item key="2" onClick={() => dispatch(push('/exams'))}>
                        <ExperimentFilled />
                        <span>Examenes {'&'} Estudios</span>
                    </Menu.Item>}
                    {(isDoctor || isAssistant) && <Menu.Item key="3" onClick={() => dispatch(push('/patients'))}>
                        <UserOutlined />
                        <span>Paciente</span>
                    </Menu.Item>}
                    {isAdmin && <Menu.Item key="4" onClick={() => dispatch(push('/admin'))}>
                        <KeyOutlined />
                        <span>Administrador</span>
                    </Menu.Item>}
                    {(isPharmacy || isAdmin) && <Menu.Item key="5" onClick={() => dispatch(push('/pharmacy'))}>
                        <HeartFilled />
                        <span>Farmacia</span>
                    </Menu.Item>}
                    {(!isAssistant || isProjectManager) && <Menu.Item key="6" onClick={() => dispatch(push('/reports'))}>
                        <FileSearchOutlined />
                        <span>Reportes</span>
                    </Menu.Item>}
                    {isAdmin && <Menu.Item key="7" onClick={() => dispatch(push('/centers'))}>
                        <DatabaseOutlined />
                        <span>Centros</span>
                    </Menu.Item>}
                    {isAdmin && <Menu.Item key="8" onClick={() => dispatch(push('/homologacion'))}>
                        <TransactionOutlined />
                        <span>Homologación</span>
                    </Menu.Item>}
                    <Menu.Item key="9" onClick={logout}>
                        <LogoutOutlined />
                        <span>Cerrar sesión</span>
                    </Menu.Item>
                </Menu>
            </Sider>}
            <Layout style={{ height: '100%' }}>
                <Content style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                    <Route exact path='/login' component={login} />
                    <Route exact path='/home' component={Main} />
                    <Route exact path='/patients' component={PatientList} />
                    <Route exact path='/doctor' component={login} />
                    <Route exact path='/admin' component={AdminPanel} />
                    <Route exact path='/pharmacy' component={PharmacyList} />
                    <Route exact path='/reports' component={Reports} />
                    <Route exact path='/centers' component={Center} />
                    <Route exact path='/exams' component={Exam} />
                    <Route exact path='/homologacion' component={Homologation} />
                </Content>
                {(user !== null && router?.location?.pathname !== '/homologacion') && <Layout.Footer>
                    <div style={{ height: '160px' }}>
                        <img
                            style={{ maxHeight: '100%', maxWidth: '100%', margin: 'auto', display: 'block' }}
                            src={banner} />
                    </div>
                </Layout.Footer>}
            </Layout>
        </Layout>
    </>
}