import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Button, notification, Select } from 'antd'
import UserModel from '../../../models/ProjectManager'
import API from '../../../utils/API'
import { AxiosError, AxiosResponse } from 'axios'

export default (props: any) => {

    const [projectManagerForm] = Form.useForm()
    const [projectManager, setAssistant] = useState<UserModel>(new UserModel)
    const [doctors, setDoctors] = useState<any[]>([])

    useEffect(() => {
        getDoctors()
        props.user?.id ? setAssistant(props.user) : setAssistant(new UserModel())
    }, [props.visible])

    useEffect(() => {
        projectManagerForm.setFieldsValue(projectManager)
    }, [projectManager])

    const send = () => {
        API.post(props.user?.id ? 'user/updateProjectManager' : 'user/addProjectManager', projectManager)
            .then(() => {
                notification.success({
                    message: 'AVISO',
                    description: 'Usuario guardado satisfactoriamente'
                })
                props.close()
            })
            .catch((error: AxiosError) => console.log(error.response?.data));
    }

    const getDoctors = () => API.get('doctor/getall').then((respose: AxiosResponse) => {
        setDoctors([...respose.data])
    })

    const onChange = (e: any) => setAssistant({ ...projectManager, ...{ [e.target.name]: e.target.value } })

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    const tailLayout = {
        wrapperCol: { offset: 6, span: 16 },
    };

    return <Modal
        visible={props.visible}
        onCancel={props.close}
        footer={[]}
        title={props.user?.id ? `${props.user?.firstName} ${props.user?.lastName}` : 'Asistente'}>
        <>
            <Form
                {...layout}
                form={projectManagerForm}
                name={'user'}
                onFinish={send}>
                <Form.Item name="cedula" label="Cédula"
                    rules={[{ required: true, message: 'Ingrese una cédula' },
                    { max: 11, message: 'Máximo 11 caracteres' },
                    () => ({
                        async validator(rule, value) {
                            let { data } = await API.post('user/cedulaexists', { username: projectManager.userId, field: value.trim() })
                            if (data)
                                return Promise.reject('Esta cédula ya existe')

                            if (!value || /^\d+$/.test(value))
                                return Promise.resolve();
                            return Promise.reject('Solo se permiten valores numéricos');
                        },
                    })]}>
                    <Input name={"cedula"} onChange={onChange} />
                </Form.Item>
                <Form.Item name="firstName" label="Nombre" rules={[{ required: true }]}>
                    <Input name={"firstName"} onChange={onChange} />
                </Form.Item>
                <Form.Item name="lastName" label="Apellido" rules={[{ required: true }]}>
                    <Input name={"lastName"} onChange={onChange} />
                </Form.Item>
                <Form.Item name="project" label="Proyecto" rules={[{ required: true }]}>
                    <Input name={"project"} onChange={onChange} />
                </Form.Item>
                <Form.Item name="email" label="e-Mail" rules={[
                    { required: true },
                    () => ({
                        async validator(rule, value) {

                            let { data } = await API.post('user/emailexists', { username: projectManager.userId, field: value.trim() })
                            if (data)
                                return Promise.reject('Este correo ya existe')
                            return Promise.resolve()
                        },
                    })]}>
                    <Input name={"email"} onChange={onChange} />
                </Form.Item>
                {!props.user?.id ?
                    <Form.Item name="username" label="Usuario" rules={[
                        { required: true },
                        () => ({
                            async validator(rule, value) {

                                let { data } = await API.get(`user/userexists?username=${value.trim()}`)
                                if (data)
                                    return Promise.reject('Este usuario ya existe')
                                return Promise.resolve()
                            },
                        })]}>
                        <Input name={"username"} onChange={onChange} />
                    </Form.Item> : <></>}
                <Form.Item {...tailLayout}>
                    <Button
                        type={'primary'}
                        htmlType={'submit'}>{props.user?.id ? 'Actualizar' : 'Guardar'}</Button>
                </Form.Item>
            </Form>
        </>
    </Modal>
}