import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, PageHeader, Row, AutoComplete, Col, DatePicker, Select, notification, Input } from 'antd';
import KendoGrid from './kendoGrid';
import API from '../../utils/API';
import moment from 'moment'
import { AxiosResponse, AxiosError } from 'axios';
import ReportFilter from '../../models/ReportFilter';

interface Option {
    value: string,
    id: string,
    data?: any
}

const { Option } = Select

export default () => {

    const createdPrescriptionsCols = [
        { field: "prescriptionId", title: "Documento", width: 'auto', footerCell: (props: any) => <td colSpan={props.colSpan} style={props.style}>65456</td> },
        { field: "paitentName", title: "Paciente", width: '300px' },
        { field: "drugName", title: "Medicamento", width: 'auto' },
        { field: "drugQuantity", title: "Cantidad", width: 'auto' },
        { field: "drugDays", title: "Días", width: 'auto' },
        { field: "drugStrength", title: "Concentración", width: 'auto' },
        { field: "type", title: "Tipo", width: 'auto' },
        { field: "created", title: "Fecha", width: 'auto', cell: (props: any) => <td colSpan={props.colSpan} style={props.style}>{moment(props.dataItem.created).format('DD/MM/YYYY hh:mm:ss A')}</td> },
        { field: "prescriber", title: "Doctor", width: '300px' }
    ]

    const pharmacyAssignedPrescriptions = [
        { field: "printed", title: "Estado", width: 'auto', cell: (props: any) => <td colSpan={props.colSpan} style={props.style}>{props.dataItem.printed ? 'Completado' : 'Pendiente'}</td> },
        { field: "prescriptionId", title: "Documento", width: 'auto' },
        { field: "paitentName", title: "Paciente", width: 'auto' },
        { field: "drugName", title: "Medicamento", width: 'auto' },
        { field: "drugQuantity", title: "Cantidad", width: 'auto' },
        { field: "drugDays", title: "Días", width: 'auto' },
        { field: "drugStrength", title: "Concentración", width: 'auto' },
        { field: "type", title: "Tipo", width: 'auto' },
        { field: "created", title: "Fecha", width: 'auto', cell: (props: any) => <td colSpan={props.colSpan} style={props.style}>{moment(props.dataItem.created).format('DD/MM/YYYY hh:mm:ss A')}</td> }
    ]

    const pendingPasswordsCols = [
        { field: "username", title: "Usuario", width: 'auto' },
        { field: "isNew", title: "Nuevo usuario", width: 'auto', cell: (props: any) => <td colSpan={props.colSpan} style={props.style}>{props.dataItem.isNew ? 'Si' : 'No'}</td> },
        { field: "created", title: "Fecha", width: 'auto', cell: (props: any) => <td colSpan={props.colSpan} style={props.style}>{moment(props.dataItem.created).format('DD/MM/YYYY hh:mm:ss A')}</td> }
    ]

    const interactionsCols = [
        { field: "prescriber", title: "Doctor", width: 'auto' },
        { field: "patient", title: "Pacient", width: 'auto' },
        { field: "severity", title: "Severidad", width: 'auto', cell: (props: any) => <td colSpan={props.colSpan} style={severityStyle(props.dataItem.severity)}>{props.dataItem.severity}</td> },
        { field: "ingredient", title: "Ingrediente", width: 'auto' },
        { field: "affected_ingredient", title: "Ingrediente afectado", width: 'auto' },
        { field: "action", title: "Acción", width: 'auto' },
        { field: "description", title: "Descripción", width: 'auto' },
        { field: "approved", title: "Aprobada", width: 'auto', cell: (props: any) => <td colSpan={props.colSpan} style={props.style}>{props.dataItem.approved ? 'Si' : 'No'}</td> },
        { field: "date", title: "Fecha", width: 'auto', cell: (props: any) => <td colSpan={props.colSpan} style={props.style}>{moment(props.dataItem.date).format('DD/MM/YYYY hh:mm:ss A')}</td> },
    ]

    const severityStyle = (value: string) => {
        return { color: getSeverity(value).color }
    };

    const [data, setData] = useState<any>([])
    const [cols, setCols] = useState(createdPrescriptionsCols)
    const [report, setReport] = useState('')
    const [chains, setChains] = useState<Option[]>()
    const [pharmacies, setPharmacies] = useState<Option[]>([])
    const [doctors, setDoctors] = useState<Option[]>([])
    const [filter, setFilter] = useState<ReportFilter>(new ReportFilter)
    const [patients, setPatients] = useState<Option[]>([])

    const { user } = useSelector((state: any) => state)

    const getCreatedPrescriptions = async () => {
        const { data } = await API.post(`report/DoctorGeneratedFiles`, filter)

        setCols([...createdPrescriptionsCols])
        setData([...data])
    }

    const getAssignedByPharmacy = async () => {
        const { data } = await API.post(`report/AssignedByPharmacy`, filter)

        setCols([...pharmacyAssignedPrescriptions])
        setData([...data])
    }

    const getPendingPasswordChanges = async () => {
        const { data } = await API.post(`report/PendingPasswordChanges`, filter)

        setCols([...pendingPasswordsCols])
        setData([...data])
    }

    const getInteractionsAudit = async () => {
        const { data } = await API.post('report/InteractionsAudit', filter)
        setCols([...interactionsCols])
        setData([...data])
    }

    const getChains = (value: string) => {
        if (!value || value.length <= 3) return;
        API.get(`pharmacy/chainsearch?name=${value}`)
            .then((response: AxiosResponse) => setChains([...response.data]))
            .catch((error: AxiosError) => console.log(error.response?.data))
    }

    const getPharmacies = (value: string) => {
        if (!value || value.length <= 3) return;
        API.post('pharmacy/search', { value, chain: user?.data?.chain || '' })
            .then((response: AxiosResponse) => {
                switch (user?.role) {
                    case 'ADMIN': setPharmacies([...response.data]); break;
                    case 'FARMACIA': {
                        if (user?.data.isHost) {
                            const filtered = response.data.filter((x: any) => x.data.chain == user?.data?.chain)
                            setPharmacies([...filtered])
                        }
                        else {
                            const filtered = response.data.filter((x: any) => x.data.id == user?.data?.pharmacy)
                            setPharmacies([...filtered])
                        }
                        break;
                    }
                    default: setPharmacies([...response.data]); break;
                }

            })
            .catch((error: AxiosError) => console.log(error.response?.data))
    }

    const getDoctors = (value: string) => {
        if (!value || value.length <= 3) return;

        API.get(`doctor/search?name=${value}`)
            .then((response: AxiosResponse) => {
                switch (user?.role) {
                    case 'ADMIN': setDoctors([...response.data]); break;
                    case 'DOCTOR': {
                        const filtered = response.data.filter((x: any) => x.data.id == user?.data.id)
                        setDoctors([...filtered])
                        break;
                    }
                }
            })
            .catch((error: AxiosError) => console.log(error.response?.data))
    }

    const getPatients = (value: string) => {
        API.post('patient/search', { value, doctor: user?.data?.id, role: user?.role, userid: user?.id })
            .then((resp: AxiosResponse) => setPatients([...resp.data]))
            .catch(() => notification.error({ message: 'Error retrieving patients"' }))

    }

    const handleChainSearch = (value: string, option: any) => setFilter({ ...filter, ...{ chainName: value, chain: value } })
    const handleChainSearchOnChange = (value: string) => setFilter({ ...filter, ...{ chain: value } })

    const handlePharmacySearch = (value: string, option: any) => setFilter({ ...filter, ...{ pharmacyCode: option.data.id, pharmacy: value } })
    const handlePharmacySearchOnChange = (value: string) => setFilter({ ...filter, ...{ pharmacy: value } })

    const handleDoctorSearch = (value: string, option: any) => setFilter({ ...filter, ...{ doctorCode: option.data.id, doctor: value } })
    const handleDoctorSearchOnChange = (value: string) => setFilter({ ...filter, ...{ doctor: value } })

    const handleDateStart = (e: any, date: string) => setFilter({ ...filter, ...{ dateStart: date } })
    const handleDateEnd = (e: any, date: string) => setFilter({ ...filter, ...{ dateEnd: date } })

    const handlePatientOnChange = (value: string, option: any) => setFilter({ ...filter, ...{ patient: option.id } })

    const handleType = (e: string) => setFilter({ ...filter, ...{ type: e } })

    const getData = () => {
        if (!report) {
            notification.warning({
                message: 'AVISO',
                description: 'Debe seleccionar un reporte.'
            })
        }

        switch (report) {
            case 'Recetas generadas': getCreatedPrescriptions(); break;
            case 'Recetas asignadas': getAssignedByPharmacy(); break;
            case 'Cambios de contraseña': getPendingPasswordChanges(); break;
            case 'Interacciones': getInteractionsAudit(); break;
        }
    }

    const getSeverity = (str: string) => {
        let val = { text: 'Menor', color: 'green' }

        switch (str) {
            case 'minor': val = { text: 'Menor', color: 'green' }
                break;
            case 'moderate': val = { text: 'Moderado', color: 'gold' }
                break;
            case 'major': val = { text: 'Mayor', color: 'red' }
                break
        }

        return val
    }

    const rowRender = (trElement: any, props: any) => {
        if (!props.dataItem.severity)
            return React.cloneElement(trElement, trElement.props.children);

        const rowHighlight = { color: getSeverity(props.dataItem.severity).color };
        const trProps = { style: rowHighlight };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    return <>
        <PageHeader title={'Reportes'} extra={<Select placeholder={'SELECCIONE UN REPORTE'} style={{ width: '200px' }} onSelect={(value: string) => setReport(value)}>
            {['ADMIN', 'DOCTOR', 'PROJECT MANAGER'].includes(user?.role) && <Option key={0} value={'Recetas generadas'}>Recetas generadas</Option>}
            {['ADMIN', 'FARMACIA'].includes(user?.role) && <Option key={1} value={'Recetas asignadas'}>Recetas asignadas</Option>}
            {['ADMIN'].includes(user?.role) && <Option key={1} value={'Cambios de contraseña'}>Cambios de contraseña</Option>}
            {['ADMIN'].includes(user?.role) && <Option key={1} value={'Interacciones'}>Interacciones</Option>}
        </Select>}
            footer={<>
                <Row gutter={12}>
                    {user?.role === 'ADMIN' && <Col span={8}>
                        Cadena
                        <Button.Group style={{ width: '100%' }}>
                            <Button type={'primary'} onClick={() => setFilter({ ...filter, ...{ chainName: '', chain: '' } })} icon={<DeleteOutlined />} />
                            <AutoComplete
                                value={filter.chain}
                                style={{ width: '90%' }}
                                onSearch={(value: string) => getChains(value)}
                                showArrow
                                placeholder={'Cadena'}
                                options={chains}
                                allowClear
                                onChange={handleChainSearchOnChange}
                                onSelect={handleChainSearch} />
                        </Button.Group>
                    </Col>}
                    <Col span={8}>
                        Farmacia
                        <Button.Group style={{ width: '100%' }}>
                            <Button type={'primary'} onClick={() => setFilter({ ...filter, ...{ pharmacyCode: 0, pharmacy: '' } })} icon={<DeleteOutlined />} />
                            <AutoComplete
                                value={filter.pharmacy}
                                style={{ width: '90%' }}
                                suffixIcon={<SearchOutlined />}
                                showArrow
                                placeholder={'Farmacia'}
                                onSelect={handlePharmacySearch}
                                onSearch={getPharmacies}
                                onChange={handlePharmacySearchOnChange}
                                options={pharmacies} />
                        </Button.Group>
                    </Col>
                    <Col span={3}>
                        Fecha inicio <br />
                        <DatePicker onChange={handleDateStart} />
                    </Col>
                    <Col span={3}>
                        Fecha fin <br />
                        <DatePicker onChange={handleDateEnd} />
                    </Col>
                </Row>
                <Row gutter={12} style={{ marginTop: '10px', marginBottom: '10px' }}>
                    {['ADMIN'].includes(user?.role) && <Col span={8}>
                        Doctor
                        <Button.Group style={{ width: '100%' }}>
                            <Button type={'primary'} onClick={() => setFilter({ ...filter, ...{ doctorCode: 0, doctor: '' } })} icon={<DeleteOutlined />} />
                            <AutoComplete
                                value={filter.doctor}
                                style={{ width: '90%' }}
                                suffixIcon={<SearchOutlined />}
                                showArrow
                                placeholder={'Doctor'}
                                onSelect={handleDoctorSearch}
                                onSearch={getDoctors}
                                onChange={handleDoctorSearchOnChange}
                                options={doctors} />
                        </Button.Group>
                    </Col>}
                    {['ADMIN'].includes(user?.role) && <Col span={5}>
                        Proyecto
                        <Input value={filter.project} onChange={(e: any) => setFilter({ ...filter, ...{ project: e.target.value } })} />
                    </Col>}
                    <Col span={3}>
                        Tipo de receta <br />
                        <Select style={{ width: '100%' }} onSelect={(value: string) => handleType(value)}>
                            <Option key={0} value={''}>Todos</Option>
                            <Option key={1} value={'Consulta'}>Consulta</Option>
                            <Option key={2} value={'Uso continuo'}>Uso continuo</Option>
                            <Option key={3} value={'Emergencia'}>Emergencia</Option>
                        </Select>
                    </Col>
                    <Col span={7}>
                        Paciente
                        <Button.Group style={{ width: '100%' }}>
                            <Button type={'primary'} onClick={() => setFilter({ ...filter, ...{ pharmacyCode: 0, pharmacy: '' } })} icon={<DeleteOutlined />} />
                            <AutoComplete
                                style={{ width: '90%' }}
                                suffixIcon={<SearchOutlined />}
                                autoClearSearchValue={true}
                                showArrow
                                placeholder={'Paciente'}
                                onSelect={handlePatientOnChange}
                                onSearch={getPatients}
                                options={patients} />
                        </Button.Group>
                    </Col>

                    <Col span={3}>
                        <br />
                        <Button icon={<SearchOutlined />} onClick={getData} type={'primary'}>Generar reporte</Button>
                    </Col>
                </Row>
            </>} />

        <KendoGrid
            data={data}
            columns={cols}
            pageSize={100}
            selectable={false}
            rowRender={rowRender} />
    </>
}