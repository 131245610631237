import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { PageHeader, Table, Row, Col, Input, Skeleton, Menu, Dropdown, notification } from 'antd'
import { UserAddOutlined, EditOutlined, FolderOpenOutlined, DeleteOutlined } from '@ant-design/icons'
import PatientModel from '../../models/Patient'
import API from '../../utils/API'
import { AxiosResponse } from 'axios'
import Button from '../common/button'
import Add from '../patient/add'
import PatientHistory from './prescriptions'
import confirm from 'antd/lib/modal/confirm'

export default () => {

    const [patients, setPatients] = useState<PatientModel[]>()
    const [addVisible, setAddVisible] = useState(false)
    const [historyVisible, setHistoryVisible] = useState(false)
    const [patient, setPatient] = useState<PatientModel>(new PatientModel)
    const [patientId, setPatientId] = useState<number>()
    const [filter, setFilter] = useState('')
    const [loading, setLoading] = useState(false)

    const { user } = useSelector((state: any) => state)

    useEffect(() => {
        getPatients()
    }, [patients?.length])

    const editPatient = (patient: PatientModel) => {
        setPatient(patient)
        toggleAddVisibility()
    }

    const newPatient = () => {
        setPatient(new PatientModel())
        toggleAddVisibility()
    }

    const showHistory = (id: number) => {
        setPatientId(id)
        toggleHistory();
    }

    const toggleAddVisibility = () => setAddVisible(!addVisible)
    const toggleHistory = () => setHistoryVisible(!historyVisible)

    const getPatients = () => {
        setLoading(true)
        if (user?.role == "ASISTENTE") {
            API.get(`patient/GetAllByAssistant?id=${user?.data?.id}`)
                .then((response: AxiosResponse) => setPatients(response.data))
                .finally(() => setLoading(false))
        }
        else {
            API.post('patient/getAll', { doctor: user?.data?.id })
                .then((response: AxiosResponse) => setPatients(response.data))
                .finally(() => setLoading(false))
        }

    }

    const deletePatient = (id: number) => {
        confirm({
            title: 'AVISO',
            content: '¿Quiere eliminar este paciente?',
            onOk: () => API.delete(`patient/delete?id=${id}`)
                .then(getPatients)
                .catch(() => notification.error({
                    message: 'PACIENTE',
                    description: 'Ocurrio un error al eliminar el paciente'
                }))
        })
    }

    const patientOptions = (record: PatientModel) => <Menu>
        <Menu.Item onClick={() => editPatient(record)}> <EditOutlined />Editar</Menu.Item>
        {user?.role == "DOCTOR" && <Menu.Item onClick={() => showHistory(record.id)}> <FolderOpenOutlined />Historial</Menu.Item>}
        {record.doctor == user.data.id && <Menu.Item onClick={() => deletePatient(record.id)}> <DeleteOutlined /> Eliminar</Menu.Item>}
    </Menu>

    const columns = [
        {
            title: '', key: 'actions', dataIndex: 'actions', render: (text: string, record: PatientModel) =>
                <Dropdown overlay={patientOptions(record)}><Button type={'link'}>. . .</Button></Dropdown>
        },
        { title: 'Nombre completo', key: 'fullName', dataIndex: 'fullName' },
        { title: 'Cedula', key: 'cedula', dataIndex: 'cedula' },
        { title: 'Edad', key: 'age', dataIndex: 'age' },
        { title: 'Teléfono', key: 'phone', dataIndex: 'phone' },
        { title: 'E-mail', key: 'email', dataIndex: 'email' },
        { title: 'Doctor', key: 'prescriber', dataIndex: 'prescriber' },
    ]
    return <>
        <PageHeader subTitle={`(${patients?.length})`}
            className="site-page-header"
            title={'Pacientes'}>
            <Button
                type={'link'}
                icon={<UserAddOutlined />}
                onClick={newPatient}>Agregar</Button>
        </PageHeader>

        <Row style={{ margin: 5 }}>
            <Col span={8}>
                <Input.Search placeholder={'Nombre o cédula...'} onChange={(e: any) => setFilter(e.target.value.toLowerCase())}></Input.Search>
            </Col>
        </Row>

        {loading ? <Skeleton loading={true} active={true} /> :
            <Table style={{ marginLeft: 10 }}
                dataSource={patients?.filter((p: PatientModel) => {
                    return p.firstName.toLowerCase().includes(filter)
                        || p.lastName.toLowerCase().includes(filter)
                        || p.cedula.toLowerCase().includes(filter)
                })}
                columns={columns}
                size={'small'} />}

        {addVisible && <Add
            visible={addVisible}
            close={toggleAddVisibility}
            patient={patient}
            refresh={getPatients} />}

        {historyVisible && <PatientHistory
            visible={historyVisible}
            close={toggleHistory}
            patientId={patientId} />}
    </>
}
