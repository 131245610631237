export default class UserAssistant {
    constructor(obj: any = null) {
        if (obj)
            Object.assign(this, obj)
    }
        id = 0
        userId = 0
        cedula = ''
        firstName = ''
        lastName = ''
        email = ''
        username = ''
        password = ''
        rePassword = ''
        doctors: Number[] = []
}