import React, { useState, useEffect } from 'react'
import { Typography, Table, Modal, Row, Col, Menu, Dropdown, notification, Tabs } from 'antd'
import { FileDoneOutlined, RedoOutlined, FilePdfOutlined } from '@ant-design/icons'
import API from '../../utils/API'
import { AxiosResponse, AxiosError } from 'axios'
import Button from '../common/button'
import Moment from 'moment'
import { useSelector } from 'react-redux'
import confirm from 'antd/lib/modal/confirm'
import PdfViewer from '../common/pdfViewer'
import StudyPdfViewer from '../common/studyPdfViewer'

const { TabPane } = Tabs;

export default (props: any) => {

    const [prescriptions, setPrescriptions] = useState<any[]>(new Array)
    const [exams, setExams] = useState<any[]>(new Array)
    const [showDrawer, setDrawer] = useState(false)
    const [showExamDrawer, setExamDrawer] = useState(false)
    const [selectedRecord, setRecord] = useState<any>()
    const [selectedExams, setExamRecord] = useState<any[]>(new Array)


    const doctorId = useSelector((state: any) => state.user?.data?.id)

    useEffect(() => {
        if (props.visible) {
            getPrescriptions()
            getExams()
        }
    }, [props.visible])

    const getPrescriptions = () => API.get(`prescription/getbypatient?patient=${props.patientId}&doctor=${doctorId}`)
        .then((response: AxiosResponse) => setPrescriptions(response.data))
        .catch((error: AxiosError) => console.log(error.response?.data))

    const getExams = () => API.get(`exam/getbypatient?patient=${props.patientId}&doctor=${doctorId}`)
        .then((response: AxiosResponse) => setExams(response.data))
        .catch((error: AxiosError) => console.log(error.response?.data))

    const toggleDrawerVisibility = () => setDrawer(!showDrawer)
    const toggleExamDrawerVisibility = () => setExamDrawer(!showExamDrawer)

    const showDocument = (record: any) => {
        API.get(`prescription/getbyid?id=${record.data[0].prescriptionId}`)
            .then((response: AxiosResponse) => {
                setRecord(response.data)
                toggleDrawerVisibility()
            })
    }

    const showExam = (record: any) => {
        API.get(`exam/getbyid?id=${record.data[0].fileId}`)
            .then((response: AxiosResponse) => {
                setExamRecord([...response.data])
                toggleExamDrawerVisibility()
            })
    }

    const duplicate = async (prescriptions: any[]) => confirm({
        title: 'AVISO',
        content: 'Se creara una receta con los mismos datos de la ya seleccionada, ¿Desea realizar esta acción?',
        onOk: async () => await API.post('prescription/duplicate', { prescriptions })
            .then(() => {
                notification.success({
                    message: 'AVISO',
                    description: 'Se ha creado una nueva receta'
                })
            })
            .finally(getPrescriptions)
    })

    const prescriptionOptions = (record: any) => <Menu>
        <Menu.Item onClick={() => showDocument(record)}> <FileDoneOutlined /> Ver </Menu.Item>
        <Menu.Item onClick={() => duplicate([...[], ...record.data.map((r: any) => r.id)])}> <RedoOutlined />  Duplicar receta</Menu.Item>
    </Menu>

    const columns = [
        {
            title: '', key: 'actions', dataIndex: 'actions', render: (text: string, record: any) =>
                <Dropdown overlay={prescriptionOptions(record)}><Button type={'link'}>. . .</Button></Dropdown>
        },
        { title: 'Id', key: 'key', dataIndex: 'key', render: (text: string) => text.toString().padStart(8, '0') },
        { title: 'Medicamento', key: 'drugs', dataIndex: 'drugs', render: (text: string, record: any) => record.data.length > 1 ? `${record.data.length} Medicamentos` : record.data[0].drugName },
        { title: 'Farmacia', key: 'pharmacy', dataIndex: 'pharmacy', render: (text: string, record: any) => record.data[0].pharmacyName },
        { title: 'Fecha', key: 'created', dataIndex: 'created', render: (text: string, record: any) => Moment(record.data[0].created).format('DD/MM/YYYY') }
    ]

    const examsColumns = [
        {
            title: '', key: 'actions', dataIndex: 'actions', render: (text: string, record: any) =>
                <Button type={'link'} icon={<FilePdfOutlined />} onClick={() => showExam(record)}>Ver</Button>
        },
        { title: 'Id', key: 'key', dataIndex: 'key', render: (text: string) => text.toString().padStart(8, '0') },
        { title: 'Estudio', key: 'exam', dataIndex: 'drugs', render: (text: string, record: any) => record.data.length > 1 ? `${record.data.length} Estudios` : record.data[0].exam },
        { title: 'Fecha', key: 'created', dataIndex: 'created', render: (text: string, record: any) => Moment(record.data[0].created).format('DD/MM/YYYY') }
    ]

    return <Modal
        destroyOnClose={true}
        closable={true}
        width={1000}
        visible={props.visible}
        onCancel={props.close}
        title={'Historial'}>
        <Tabs defaultActiveKey="1" onChange={() => { }}>
            <TabPane tab="Prescripciones" key="1">
                <Table
                    dataSource={prescriptions}
                    columns={columns}
                    expandable={{ rowExpandable: (record: any) => record.data.length > 1 }}
                    expandedRowRender={(record: any) => <>
                        {record.data.map((drug: any) => <Row gutter={16} style={{ margin: '5px' }}>
                            <Col>
                                <Button
                                    type={'link'}
                                    icon={<RedoOutlined />}
                                    onClick={() => duplicate([drug.id])}>Duplicar receta</Button>
                            </Col>
                            <Col>
                                <Typography.Text strong key={drug.id}>{drug.drugName}</Typography.Text>
                            </Col>
                            <Col>
                                <Typography.Text>{drug.drugAdministrationComment}</Typography.Text>
                            </Col>
                        </Row>)}

                    </>}
                    size={'small'} />
            </TabPane>
            <TabPane tab={'Pruebas de laboratorio'} key="2">
                <Table
                    dataSource={exams.filter((exam: any) => exam.data[0].type == 'Laboratorio')}
                    columns={examsColumns}
                    expandable={{ rowExpandable: (record: any) => record.data.length > 1 }}
                    expandedRowRender={(record: any) => <>
                        {record.data.map((exam: any) => <Row gutter={16} style={{ margin: '5px' }}>
                            <Col>
                                <Typography.Text strong key={exam.fileId}>{exam.exam}</Typography.Text>
                            </Col>
                        </Row>)}

                    </>}
                    size={'small'} />
            </TabPane>
            <TabPane tab={'Exámenes auxiliares'} key="3">
                <Table
                    dataSource={exams.filter((exam: any) => exam.data[0].type == 'Examenes Auxiliares')}
                    columns={examsColumns}
                    expandable={{ rowExpandable: (record: any) => record.data.length > 1 }}
                    expandedRowRender={(record: any) => <>
                        {record.data.map((exam: any) => <Row gutter={16} style={{ margin: '5px' }}>
                            <Col>
                                <Typography.Text strong key={exam.fileId}>{exam.exam}</Typography.Text>
                            </Col>
                        </Row>)}

                    </>}
                    size={'small'} />
            </TabPane>
        </Tabs>

        {showDrawer && <PdfViewer
            data={selectedRecord}
            onClose={toggleDrawerVisibility}
            visible={showDrawer} />}

        {selectedExams.length && <StudyPdfViewer
            data={selectedExams}
            onClose={toggleExamDrawerVisibility}
            visible={showExamDrawer} />}
    </Modal>
}