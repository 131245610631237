const SETCENTER = 'SET_CENTER';
const initialState = { center: {} };

export const actionCreators = {
    setCenter: (center: {}) => ({ type: SETCENTER,  center}),
};

export const reducer = (state: any, action: any) => {
    state = state || initialState;
    if (action.type === SETCENTER)
        return { ...state, ...{ center: action.center } };

    return state;
};
