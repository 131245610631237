import React, { useMemo } from 'react'
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import moment from 'moment'
import logo from '../../assets/images/logoalt.png'

import RobotoThin from '../../assets/Roboto-Thin.ttf'
import RobotoBlack from '../../assets/Roboto-Black.ttf'
import RobotoLight from '../../assets/Roboto-Light.ttf'
import RobotoThinItalic from '../../assets/Roboto-ThinItalic.ttf'
import AlluraRegular from '../../assets/Allura-Regular.ttf'
import TangerineBold from '../../assets/Tangerine-Bold.ttf'

export default (props) => {

    const getAge = () => {
        var diff_ms = Date.now() - new Date(props.data[0].patientDateOfBirth).getTime();
        var age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    Font.register({
        family: 'Allura-Regular',
        src: AlluraRegular,
    })

    Font.register({
        family: 'Tangerine-Bold',
        src: TangerineBold,
    })

    Font.register({
        family: 'Oswald',
        src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
    });

    Font.register({
        family: 'Roboto-Thin',
        src: RobotoThin
    });

    Font.register({
        family: 'Roboto-Black',
        src: RobotoBlack
    });

    Font.register({
        family: 'Roboto-Light',
        src: RobotoLight
    });

    Font.register({
        family: 'Roboto-ThinItalic',
        src: RobotoThinItalic
    });

    const styles = useMemo(() => {
        return StyleSheet.create({
            body: {
                paddingTop: 35,
                paddingBottom: 65,
                paddingHorizontal: 35,
            },
            title: {
                fontSize: 15,
                textAlign: 'center',
                fontFamily: 'Roboto-Black'
            },
            author: {
                fontSize: 10,
                textAlign: 'center',
                marginBottom: 5,
                fontFamily: 'Roboto-Light'
            },
            subtitle: {
                fontSize: 14,
                margin: 12,
                fontFamily: 'Oswald'
            },
            text: {
                margin: 12,
                fontSize: 10,
                textAlign: 'justify',
                fontFamily: 'Times-Roman'
            },
            textCellNormal: {
                margin: 1,
                fontSize: 8,
                fontFamily: 'Roboto-Light'
            },
            textCell: {
                margin: 1,
                fontSize: 8,
                textAlign: 'justify',
                fontFamily: 'Roboto-Light'
            },
            textCellCenter: {
                margin: 1,
                fontSize: 8,
                textAlign: 'center',
                fontFamily: 'Roboto-Light'
            },
            textCellItalic: {
                margin: 1,
                fontSize: 10,
                textAlign: 'left',
                fontFamily: 'Roboto-ThinItalic'
            },
            textCellBold: {
                margin: 1,
                fontSize: 8,
                textAlign: 'left',
                fontFamily: 'Roboto-Black'
            },
            image: {
                position: 'relative',
                bottom: 0,
                left: 30,
                height: 120,
                width: 140
            },
            signatureText: {
                position: 'relative',
                bottom: 0,
                left: 0,
                width: 200,
                textAlign: 'center',
                fontFamily: 'Oswald',
                fontSize: 10
            },
            header: {
                fontSize: 10,
                marginBottom: 5,
                textAlign: 'center',
                color: 'grey',
                fontFamily: 'Roboto-Black'
            },
            pageNumber: {
                position: 'absolute',
                fontSize: 8,
                bottom: 10,
                left: 0,
                right: 0,
                textAlign: 'center',
                color: 'grey',
            },
            tableTitle: {
                fontSize: 10,
                textAlign: 'left',
                marginBottom: 2,
                marginTop: 10,
                color: '#B12029',
                fontFamily: 'Roboto-Black'
            },
            table: {
                display: "table",
                width: "auto",
            },
            tableRow: {
                margin: "auto",
                marginBottom: '1px',
                marginTop: '1px',
                flexDirection: "row"
            },
            tableRowBorder: {
                margin: "auto",
                flexDirection: "row",
                borderBottom: 1,
                marginBottom: '5px'
            },
            tableRowComment: {
                margin: "0",
                flexDirection: "row"
            },
            tableColHeader: {
                width: "25%",
            },
            tableColHeader70: {
                width: "70%",
            },
            tableColHeader15: {
                width: "15%",
            },
            tableCol: {
                width: "25%",
            },
            tableCol15: {
                width: "15%",
            },
            tableCol40: {
                width: "40%",
            },
            tableCol50: {
                width: "50%",
            },
            tableCol60: {
                width: "60%",
            },
            tableCol70: {
                width: "70%",
            },
            tableCol100: {
                width: "100%",
                textAlign: 'left'
            },
            tableCellHeader: {
                textAlign: 'left',
                margin: "auto",
                fontSize: 10,
                fontWeight: 500,
                fontFamily: 'Roboto-Black'
            },
            tableCell: {
                textAlign: 'left',
                // margin: "auto",
                marginBottom: '1px',
                marginTop: '1px',
                fontSize: 8
            },
            drugNameCell: {
                textAlign: 'left',
                // margin: "auto",
                marginBottom: '1px',
                marginTop: '1px',
                fontSize: 8,
                fontFamily: 'Roboto-Black'
            },
            logo: {
                margin: 'auto',
                marginBottom: '5px',
                marginTop: '0px',
                height: '70px',
                width: '180px',
                position: 'relative'
            },
            logos: {
                position: 'absolute',
                bottom: 60,
                left: 0,
                right: 0,
                textAlign: 'center',
            },
            logosalt: {
                position: 'absolute',
                bottom: 30,
                left: 0,
                right: 30,
                textAlign: 'center',
            },
            imagealt: {
                position: 'relative',
                bottom: 0,
                left: 10,
                height: 50,
                width: 110,
                textAlign: 'center'
            },
            pageDate: {
                position: 'absolute',
                fontSize: 8,
                bottom: 20,
                left: 0,
                right: 0,
                textAlign: 'center',
                color: 'grey',
            },
            miniLogo: {
                position: 'absolute',
                fontSize: 8,
                bottom: 70,
                left: 0,
                right: 0,
                textAlign: 'center',
                color: 'grey',
                height: '40px',
                width: '60'
            },
            center: {
                textAlign: 'center'
            },
            doctorName: {
                fontSize: 30,
                textAlign: 'center',
                fontFamily: 'Tangerine-Bold'
            },
        })
    }, [])

    const genderSuffix = props.data[0].prescriberGender == 'F' ? 'Dra.' : 'Dr.'
    const prescriberFirstName = capitalize(props.data[0].prescriberFirstName.toLowerCase() || '')
    const prescriberLastName = capitalize(props.data[0].prescriberLastName.toLowerCase() || '')

    function capitalize(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    return <Document id={'examDoc'} onRender={(e) => props.onRender && props.onRender(e)}>
        <Page size={'Letter'} style={styles.body}>
            <Text style={styles.header} fixed>
                {`~ Estudio Clínico #${props.data[0].fileId.toString().padStart(8, "0")} ~`}
            </Text>

            <Text style={styles.doctorName}>{`${genderSuffix} ${prescriberFirstName} ${prescriberLastName}`}</Text>
            <Text style={styles.author}>{props.data[0].prescriberSpecialty || '~'} - {'EXEQUÁTUR: '}{props.data[0].prescriberExequatur || '~'}</Text>
            <Text style={styles.author}>{props.data[0].prescriberCenterName}</Text>
            <Text style={styles.author}>{props.data[0].prescriberPhone ? `Teléfono: ${props.data[0].prescriberPhone}` : ''} | {props.data[0].prescriberMail ? `Correo: ${props.data[0].prescriberMail}` : ''}</Text>

            <Text style={styles.tableTitle}>{'Paciente'}</Text>
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol60}>
                        <Text style={[styles.tableCell, styles.textCell]}>{`Nombre: ${props.data[0].patientFirstName || ''} ${props.data[0].patientLastName || ''}`}</Text>
                    </View>
                    <View style={styles.tableCol40}>
                        <Text style={[styles.tableCell, styles.textCell]}>{`Edad: ${getAge()}`}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol60}>
                        <Text style={[styles.tableCell, styles.textCell]}>{`Teléfono: ${props.data[0].patientPhone || '-'}`}</Text>
                    </View>
                    <View style={styles.tableCol40}>
                        <Text style={[styles.tableCell, styles.textCell]}>{`Correo electrónico: ${props.data[0].patientEmail || ''}`}</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol60}>
                        <Text style={[styles.tableCell, styles.textCell]}>{`Dirección: ${props.data[0].patientAddress || ''}`}</Text>
                    </View>
                    <View style={styles.tableCol40}>
                        <Text style={[styles.tableCell, styles.textCell]}>{`Aseguradora: ${props.data[0].patientInsurance || ''}`}</Text>
                    </View>
                </View>
                {props.data[0].patientAffiliateCode ? <View style={styles.tableRow}>
                    <View style={styles.tableCol40}>
                        <Text style={[styles.tableCell, styles.textCell]}>{`# Afiliado: ${props.data[0].patientAffiliateCode || ''}`}</Text>
                    </View>
                    <View style={styles.tableCol60}>
                        <Text style={[styles.tableCell, styles.textCell]}>{``}</Text>
                    </View>
                </View> : <View></View>}
            </View>

            <Text style={styles.tableTitle}>{'Estudios'}</Text>
            <View style={styles.table}>
                {
                    [...new Set(props.data.map(s => s.examCategory))].map((c, idx) => <View key={idx}>
                        <Text style={[styles.drugNameCell]}>{`${c}`}</Text>
                        {
                            props.data.filter(e => e.examCategory == c)
                                .map((exam, idx) => <Text key={idx} style={[styles.textCellNormal]}>{`${exam.exam}`}</Text>)
                        }
                    </View>)
                }
            </View>

            <View style={styles.logos}>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol33}>
                        {props.data[0].prescriberSignature ? <Image
                            fixed
                            style={[styles.image]}
                            src={props.data[0].prescriberSignature || ''}
                        /> : <View></View>}
                        <Text fixed style={[styles.signatureText]}>{`________________________________`}</Text>
                        <Text fixed style={styles.signatureText}>{`Firma`}</Text>
                    </View>

                    <View style={styles.tableCol33}>
                        {props.data[0].prescriberSignature ? <Image
                            fixed
                            style={[styles.image]}
                            src={props.data[0].prescriberStamp}
                        /> : <View></View>}
                        <Text fixed style={[styles.signatureText]}>{`________________________________`}</Text>
                        <Text fixed style={styles.signatureText}>{`Sello`}</Text>
                    </View>
                </View>
            </View>

            <View style={styles.logosalt}>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol33}>
                    </View>
                    <View style={styles.tableCol33}>
                        <Image
                            fixed
                            style={[styles.imagealt]}
                            src={logo}
                        />
                    </View>
                    <View style={styles.tableCol33}>
                    </View>
                </View>
            </View>

            <Text style={styles.pageDate} fixed>
                {moment(props.data[0].created).local().format('DD/MM/YYYY hh:mm a')}
            </Text>

            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
            )} fixed />
        </Page>
    </Document >
}