export default class CenterModel {
    constructor(obj: any = null) {
        if (obj)
            Object.assign(this, obj)
    }

    id = 0
    name = ''
    address = ''
    phone = ''
}