import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Checkbox, Button, Upload, notification, Row, Col, Select } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import UserModel from '../../../models/UserDoctor'
import API from '../../../utils/API'
import { AxiosError, AxiosResponse } from 'axios'

const { Option } = Select

export default (props: any) => {

    const [doctorForm] = Form.useForm()
    const [doctor, setDoctor] = useState<UserModel>(new UserModel)
    const [centers, setCenters] = useState<any[]>([])
    const [selectedCenters, setSelectedCenters] = useState<any[]>([])

    useEffect(() => {
        getCenters()
        if (props.user?.id) {
            getRelatedCenters()
            setDoctor(props.user)
        }
        else
            setDoctor(new UserModel())
    }, [props.visible])

    useEffect(() => {
        doctorForm.setFieldsValue(doctor)
    }, [doctor])

    const send = () => {
        console.log(doctor)
        API.post(props.user?.id ? 'user/updateDoctor' : 'user/addDoctor', {...doctor, ...{centers: selectedCenters}})
        .then(() => {
            notification.success({
                message: 'AVISO',
                description: 'Usuario guardado satisfactoriamente'
            })
            props.close()
        })
        .catch((error: AxiosError) => console.log(error.response?.data));
    }

    const getCenters = () => API.get('user/centers').then((respose: AxiosResponse) => setCenters([...respose.data]))
    const getRelatedCenters = () => API.get(`user/getRelatedCenters?doctorId=${props.user?.id}`).then((response: AxiosResponse) => {
        if (response.data.length > 0)
            setSelectedCenters([...response.data.map((c: any) => c.center)])
    })

    const onChange = (e: any) => setDoctor({ ...doctor, ...{ [e.target.name]: e.target.value } })
    const onCheckChange = (e: any) => setDoctor({ ...doctor, ...{ [e.target.name]: e.target.checked } })
    const onSelect = (value: number) => setSelectedCenters([...selectedCenters, value])
    const onDeselect = (value: any) => setSelectedCenters(selectedCenters.filter((c: any) => c != value))
    const genderSelected = (value: string) => setDoctor({ ...doctor, ...{ gender: value } })

    const handleSignatureUpload = (file: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            API.post('doctor/uploadsignature', { file: reader.result, doctorId: props.user?.id })
                .then(() => notification.success({
                    message: 'Carga completada',
                    description: 'Firma cargada correctamente'
                }))
                .catch(() => notification.error({
                    message: 'Error',
                    description: 'Ocurrió un error al cargar la firma'
                }))
        }
        reader.onerror = () => notification.error({
            message: 'Error',
            description: 'Ocurrió un error al cargar la firma'
        })
        return false;
    }

    const handleStampUpload = (file: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            API.post('doctor/uploadstamp', { file: reader.result, doctorId: props.user?.id })
                .then(() => notification.success({
                    message: 'Carga completada',
                    description: 'Sello cargada correctamente'
                }))
                .catch(() => notification.error({
                    message: 'Error',
                    description: 'Ocurrió un error al cargar la Sello'
                }))
        }
        reader.onerror = () => notification.error({
            message: 'Error',
            description: 'Ocurrió un error al cargar la Sello'
        })
        return false;
    }

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    const tailLayout = {
        wrapperCol: { offset: 6, span: 16 },
    };

    return <Modal
        visible={props.visible}
        onCancel={props.close}
        footer={[]}
        title={props.user?.id ? `${props.user?.firstName} ${props.user?.lastName}` : 'Doctor'}>
        <>
            <Form
                {...layout}
                form={doctorForm}
                name={'user'}
                onFinish={send}>
                <Form.Item name="exequatur" label="Exequátur" rules={[{ required: true }]}>
                    <Input name={"exequatur"} onChange={onChange} />
                </Form.Item>
                <Form.Item name="cedula" label="Cédula"
                    rules={[{ required: true, message: 'Ingrese una cédula' },
                    { max: 11, message: 'Máximo 11 caracteres' },
                    () => ({
                        async validator(rule, value) {
                            let { data } = await API.post('user/cedulaexists', { username: doctor.userId, field: value.trim() })
                            if (data)
                                return Promise.reject('Esta cédula ya existe')

                            if (!value || /^\d+$/.test(value))
                                return Promise.resolve();
                            return Promise.reject('Solo se permiten valores numéricos');
                        },
                    })]}>
                    <Input name={"cedula"} onChange={onChange} />
                </Form.Item>
                <Form.Item name="firstName" label="Nombre" rules={[{ required: true }]}>
                    <Input name={"firstName"} onChange={onChange} />
                </Form.Item>
                <Form.Item name="lastName" label="Apellido" rules={[{ required: true }]}>
                    <Input name={"lastName"} onChange={onChange} />
                </Form.Item>
                <Form.Item
                    label={'Sexo'}
                    name={'gender'}
                    rules={[{ required: true, message: 'Sexo requerido' }]}>
                    <Select
                        onSelect={genderSelected}>
                        <Option key={1} value={'M'}>MASCULINO</Option>
                        <Option key={2} value={'F'}>FEMENINO</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="project" label="Proyecto" rules={[{ required: false }]}>
                    <Input name={"project"} onChange={onChange} />
                </Form.Item>
                <Form.Item name="specialty" label="Especialidad" rules={[{ required: true }]}>
                    <Input name={"specialty"} onChange={onChange} />
                </Form.Item>
                <Form.Item label="Centros">
                    <Select
                        value={selectedCenters}
                        mode={'multiple'}
                        placeholder={'Centros...'}
                        onSelect={onSelect}
                        onDeselect={onDeselect}
                    >
                        {centers.map((center: any) => <Select.Option key={center.id} value={center.id}>{center.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item name="isFuturo" label="ARS Futuro" rules={[{ required: true }]}>
                    <Checkbox checked={doctor.isFuturo} name={"isFuturo"} onChange={onCheckChange} />
                </Form.Item>
                <Form.Item name="phone" label="Teléfono">
                    <Input name={"phone"} onChange={onChange} />
                </Form.Item>
                <Form.Item name="email" label="e-Mail" rules={[
                    { required: true },
                    () => ({
                        async validator(rule, value) {

                            let { data } = await API.post('user/emailexists', { username: doctor.userId, field: value.trim() })
                            if (data)
                                return Promise.reject('Este correo ya existe')
                            return Promise.resolve()
                        },
                    })]}>
                    <Input name={"email"} onChange={onChange} />
                </Form.Item>
                {!props.user?.id ? <>
                    <Form.Item name="username" label="Usuario" rules={[
                        { required: true },
                        () => ({
                            async validator(rule, value) {

                                let { data } = await API.get(`user/userexists?username=${value.trim()}`)
                                if (data)
                                    return Promise.reject('Este usuario ya existe')
                                return Promise.resolve()
                            },
                        })]}>
                        <Input name={"username"} onChange={onChange} />
                    </Form.Item>
                </> : <>
                    <Form.Item {...tailLayout}>
                        <Row gutter={8} justify={'space-between'}>
                            <Col span={12}>
                                <Upload beforeUpload={handleSignatureUpload}>
                                    <Button disabled={props.user?.signature != ''}><UploadOutlined /> Cargar firma</Button>
                                </Upload>
                            </Col>
                            <Col span={12}>
                                <Upload beforeUpload={handleStampUpload}>
                                    <Button disabled={props.user?.stamp != ''}><UploadOutlined /> Cargar sello</Button>
                                </Upload>
                            </Col>
                        </Row>
                        <Row gutter={8} justify={'space-between'}>
                            <Col span={12}>
                                <img style={{ height: 100 }} src={props.user?.signature} />
                            </Col>
                            <Col span={12}>
                                <img style={{ height: 100 }} src={props.user?.stamp} />
                            </Col>
                        </Row>
                    </Form.Item>
                </>}
                <Form.Item {...tailLayout}>
                    <Button
                        type={'primary'}
                        htmlType={'submit'}>{props.user?.id ? 'Actualizar' : 'Guardar'}</Button>
                </Form.Item>
            </Form>
        </>
    </Modal>
}