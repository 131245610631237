import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Checkbox, Button, Upload, notification, Row, Col, Select } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import UserModel from '../../../models/UserAssistant'
import API from '../../../utils/API'
import { AxiosError, AxiosResponse } from 'axios'

const { Option } = Select

export default (props: any) => {

    const [assistantForm] = Form.useForm()
    const [assistant, setAssistant] = useState<UserModel>(new UserModel)
    const [doctors, setDoctors] = useState<any[]>([])
    const [selectedDoctors, setSelectedDoctors] = useState<any[]>([])

    useEffect(() => {
        getDoctors()

        props.user?.id ? setAssistant(props.user) : setAssistant(new UserModel())
    }, [props.visible])

    useEffect(() => {
        if (doctors.length > 0)
            getRelatedDoctors()
    }, [doctors])

    useEffect(() => {
        assistantForm.setFieldsValue(assistant)
    }, [assistant])

    const send = () => {
        API.post(props.user?.id ? 'user/updateAssistant' : 'user/addAssistant', { ...assistant, ...{ doctors: selectedDoctors } })
            .then(() => {
                notification.success({
                    message: 'AVISO',
                    description: 'Usuario guardado satisfactoriamente'
                })
                props.close()
            })
            .catch((error: AxiosError) => console.log(error.response?.data));
    }

    const getDoctors = () => API.get('doctor/getall').then((respose: AxiosResponse) => {
        setDoctors([...respose.data])
    })

    const getRelatedDoctors = () => {
        if (!props.user?.id) return;

        API.get(`user/getRelatedDoctors?assistantId=${props.user?.id}`).then((respose: AxiosResponse) => {
            console.log(respose.data)
            setSelectedDoctors([...respose.data.map((doc: any) => doc.id)])
        })
    }

    const onChange = (e: any) => setAssistant({ ...assistant, ...{ [e.target.name]: e.target.value } })
    const onSelect = (value: number) => setSelectedDoctors([...selectedDoctors, value])
    const onDeselect = (value: any) => setSelectedDoctors(selectedDoctors.filter((c: any) => c != value))

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    const tailLayout = {
        wrapperCol: { offset: 6, span: 16 },
    };

    return <Modal
        visible={props.visible}
        onCancel={props.close}
        footer={[]}
        title={props.user?.id ? `${props.user?.firstName} ${props.user?.lastName}` : 'Asistente'}>
        <>
            <Form
                {...layout}
                form={assistantForm}
                name={'user'}
                onFinish={send}>
                <Form.Item name="cedula" label="Cédula"
                    rules={[{ required: true, message: 'Ingrese una cédula' },
                    { max: 11, message: 'Máximo 11 caracteres' },
                    () => ({
                        async validator(rule, value) {
                            let { data } = await API.post('user/cedulaexists', { username: assistant.userId, field: value.trim() })
                            if (data)
                                return Promise.reject('Esta cédula ya existe')

                            if (!value || /^\d+$/.test(value))
                                return Promise.resolve();
                            return Promise.reject('Solo se permiten valores numéricos');
                        },
                    })]}>
                    <Input name={"cedula"} onChange={onChange} />
                </Form.Item>
                <Form.Item name="firstName" label="Nombre" rules={[{ required: true }]}>
                    <Input name={"firstName"} onChange={onChange} />
                </Form.Item>
                <Form.Item name="lastName" label="Apellido" rules={[{ required: true }]}>
                    <Input name={"lastName"} onChange={onChange} />
                </Form.Item>
                <Form.Item label="Doctores">
                    <Select
                        value={[...selectedDoctors]}
                        mode={'multiple'}
                        placeholder={'Doctores...'}
                        onSelect={onSelect}
                        onDeselect={onDeselect}>
                        {doctors.map((doctor: any) => <Select.Option key={doctor.id} value={doctor.id}>{doctor.name}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item name="email" label="e-Mail" rules={[
                    { required: true },
                    () => ({
                        async validator(rule, value) {

                            let { data } = await API.post('user/emailexists', { username: assistant.userId, field: value.trim() })
                            if (data)
                                return Promise.reject('Este correo ya existe')
                            return Promise.resolve()
                        },
                    })]}>
                    <Input name={"email"} onChange={onChange} />
                </Form.Item>
                {!props.user?.id ?
                    <Form.Item name="username" label="Usuario" rules={[
                        { required: true },
                        () => ({
                            async validator(rule, value) {

                                let { data } = await API.get(`user/userexists?username=${value.trim()}`)
                                if (data)
                                    return Promise.reject('Este usuario ya existe')
                                return Promise.resolve()
                            },
                        })]}>
                        <Input name={"username"} onChange={onChange} />
                    </Form.Item> : <></>}
                <Form.Item {...tailLayout}>
                    <Button
                        type={'primary'}
                        htmlType={'submit'}>{props.user?.id ? 'Actualizar' : 'Guardar'}</Button>
                </Form.Item>
            </Form>
        </>
    </Modal>
}